import React, { useState } from 'react';
import { NavLink, Navigate, useParams } from 'react-router-dom';
import s from './PrivateOffice.module.css';
import Modal from '../Production/common/Modal/Modal';
import PrivateOfficeBlocks from './PrivateOfficeBlocks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'js-cookie';

const PrivateOffice = (props) => {
    let [modal, showModal] = useState(false);
    let [modalAddForBlock, showModalAddForBlock] = useState(false);

    const setShowPersonalOfficeFieldF = (id, block, personalId) => {
        props.setShowPersonalOfficeField(id, block, personalId);
        showModalAddForBlock(false);
    };

    let [deleteBlock, setDeleteBlock] = useState(false);
    const setDeleteBlockF = () => {
        props.deletePersonalFiltrBlock(deleteBlock, 1, props.personalId);
        setDeleteBlock(false);
    };

    let [deleteSmallBlock, setDeleteSmallBlock] = useState(false);
    const setDeleteSmallBlockF = () => {
        props.deletePersonalFiltrBlock(deleteSmallBlock, 2, props.personalId);
        setDeleteSmallBlock(false);
    };

    let [newNameForBlock, showModalNewNameForBlock] = useState(false);
    const showModalNewNameForBlockF = () => {
        let postParams = {
            new_name: text,
            block_id: newNameForBlock,
            personalId: props.personalId,
        };
        props.editPersonalFiltrBlockName(postParams);
        setText('');
        showModalNewNameForBlock(false);
    };

    let [text, setText] = useState('');
    const setNewBlockName = (e) => {
        setText(e.target.value);
    };

    let [newColorBgInPersonalFilter, setNewColorBgInPersonalFilter] = useState(false);
    const setNewColorBgInPersonalFilterF = (newColor) => {
        props.setColorBgInPersonalFilter(newColorBgInPersonalFilter, newColor, props.personalId);
        setNewColorBgInPersonalFilter(false);
    };

    const styleBg = (colorBg) => {
        return { background: colorBg };
    };

    const styleBgArray = ['0000ff', '008000', 'ff0000', 'ff9900', '000000', '0f6ae2'];
    const showCourse = (id) => {
        return [44, 34, 486, 1055, 1056, 1061].includes(parseInt(id));
    };

    const setMonthInLink = (id, link) => {
        let date = new Date();
        let month = date.getMonth() + 1;
        return id === 36 ? `${link}${month}` : link;
    };

    const renderLink = (j) => {
        const link = j.personalLink ? `${j.link}/${props.personalId}` : setMonthInLink(j.id, j.link);
        const style = j.colorBg !== 'NULL' ? styleBg(`#${j.colorBg}`) : {};
        const content = (
            <div key={`filtrWants${j.id}`} className={s.smallBlock} style={style}>
                <div className={s.text}>
                    {j.name}
                    {j.id === 16 && props?.countOverdueCheckTest !== 0 && <span className={s.number}>{props.countOverdueCheckTest}</span>}
                    {j.id === 17 && props?.countOverdueCheckTestBack !== 0 && <span className={s.number}>{props.countOverdueCheckTestBack}</span>}
                    {j.id === 38 && props?.countRepairFromSite !== 0 && <span className={s.number}>{props.countRepairFromSite}</span>}
                </div>
            </div>
        );

        return j.is_new === 1 ? (
            <NavLink to={`/${link}`} target="_blank">
                {content}
            </NavLink>
        ) : (
            <a href={link} target="_blank">
                {content}
            </a>
        );
    };

    const { personalId } = useParams(); // Use useParams to get params from the URL
    return (
        <div>
            {personalId !== Cookies.get('id') && <Navigate to={`/privateOffice/${Cookies.get('id')}`} />}
            {modal && (
                <Modal active={modal} setActive={showModal}>
                    <PrivateOfficeBlocks showModal={showModal} addNewPersonalFiltrBlock={props.addNewPersonalFiltrBlock} />
                </Modal>
            )}
            {deleteBlock && (
                <Modal active={deleteBlock} setActive={setDeleteBlock}>
                    <div className={s.addComment}>
                        <div>
                            <h3>Удаление блока</h3>
                        </div>
                        <div className={s.center}>
                            <button onClick={() => setDeleteBlockF()} className={s.button}>
                                Удалить
                            </button>
                        </div>
                    </div>
                </Modal>
            )}
            {deleteSmallBlock && (
                <Modal active={deleteSmallBlock} setActive={setDeleteSmallBlock}>
                    <div className={s.addComment}>
                        <div>
                            <h3>Удаление ссылки</h3>
                        </div>
                        <div className={s.center}>
                            <button onClick={() => setDeleteSmallBlockF()} className={s.button}>
                                Удалить
                            </button>
                        </div>
                    </div>
                </Modal>
            )}
            {modalAddForBlock && (
                <Modal active={modalAddForBlock} setActive={showModalAddForBlock}>
                    <div className={s.filtr}>
                        {props?.privateOffice?.map((k) => (
                            <div
                                key={`filtrWants${k.id}`}
                                onClick={() => setShowPersonalOfficeFieldF(k.id, modalAddForBlock, props.personalId)}
                                className={k.private_office_blocks_id === modalAddForBlock ? s.dontShow : ''}
                            >
                                {k.name}
                            </div>
                        ))}
                    </div>
                </Modal>
            )}
            {newNameForBlock && (
                <Modal active={newNameForBlock} setActive={showModalNewNameForBlock}>
                    <div className={s.center}>
                        <h1>Изменение имени блока</h1>
                        <div>
              <textarea
                  onChange={setNewBlockName}
                  placeholder="Название блока"
                  className={s.textarea}
                  maxLength={50}
              />
                        </div>
                        <div>
                            <div className={s.center}>
                                <button onClick={() => showModalNewNameForBlockF()} className={s.button}>
                                    Изменить
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
            {newColorBgInPersonalFilter && (
                <Modal active={newColorBgInPersonalFilter} setActive={setNewColorBgInPersonalFilter}>
                    <div className={s.addComment}>
                        <div>
                            <h3>Выбрать цвет</h3>
                        </div>
                        <div className={s.linkBg}>
                            {styleBgArray?.map((k) => (
                                <div
                                    key={`color${k}`}
                                    className={s.smallBlockBg}
                                    style={styleBg(`#${k}`)}
                                    onClick={() => setNewColorBgInPersonalFilterF(k)}
                                >
                                    Пример
                                </div>
                            ))}
                        </div>
                    </div>
                </Modal>
            )}
            <div className={s.twoBlocksWithH1}>
                <div onClick={() => showModal(true)} className={s.button}>
                    Добавить раздел
                </div>
                <div>
                    <h1 className={s.center}>Личный кабинет</h1>
                </div>
                {showCourse(props?.personalId) && (
                    <div className={s.course}>
                        {props?.course?.map((k, index) => (
                            <div key={`course${k.index + k.currency + k.numeral}`} className={index === 0 ? s.blueBg : s.yellowBg}>
                                {k.currency + ': ' + k.numeral}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div>
                {props?.privateOfficeBlocks?.map((k) => {
                    return (
                        <div key={`filtrBlockNew_${k.id}`} className={s.filtrBlockNew}>
                            <div className={s.firstBlock}>
                                <div>
                                    <h2>{k.name ?? ''}</h2>
                                </div>
                                <div className={s.greenNew} onClick={() => showModalAddForBlock(k.id)}>
                                    <FontAwesomeIcon icon={faPlus} />
                                </div>
                                <div className={s.yellowNew} onClick={() => showModalNewNameForBlock(k.id)}>
                                    <FontAwesomeIcon icon={faPencilAlt} />
                                </div>
                                <div className={s.redNew} onClick={() => setDeleteBlock(k.id)}>
                                    <FontAwesomeIcon icon={faTrash} />
                                </div>
                            </div>
                            <div className={s.link}>
                                {props?.privateOffice.map((j) => {
                                    return (
                                        j.private_office_blocks_id === k.id && (
                                            <div className={s.mainSmallBlock} key={`mainSmallBlock_${j.filterId}`}>
                                                <div className={s.redNewSmallBlock}>
                          <span onClick={() => setNewColorBgInPersonalFilter(j.filterId)} className={s.green}>
                            <FontAwesomeIcon icon={faPencilAlt} />
                          </span>
                                                    <span onClick={() => setDeleteSmallBlock(j.filterId)}>
                            <FontAwesomeIcon icon={faTrash} />
                          </span>
                                                </div>
                                                {renderLink(j)}
                                            </div>
                                        )
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default PrivateOffice;
