import axios from "axios";
import Cookies from "js-cookie";
import {toast} from "react-toastify";

const instance = axios.create({
    withCredentials: false,
    baseURL: "https://master.akon.com.ua/api/"
});
export default instance;

const newApiInstance = axios.create({
    withCredentials: false,
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    // add "token" header to all rest headers
    headers: {
        'token': Cookies.get('token'),
        'user_id': Cookies.get('id'),
        'userID': Cookies.get('id'),
        'adminID': Cookies.get('adminID'),
    }
    // headers: {
    //     'X-Staging-Environment': 'true',
    // }
});

export const techAPI = {
    getCompanyListNameWithAkon(query) {
        return newApiInstance.get('company/list/name-with-akon', {params: query})
            .then(response => {
                return response.data
            });
    },
    getListOfTechConsultations(params) {
        return newApiInstance.get('tech/consultation-list', {params: params})
            .then(response => {
                return response.data
            });

    },
    updateStatus(id, status) {
        return newApiInstance.post('tech/consultation-status', {id, status})
            .then(response => {
                return response.data
            });
    },
    addComment(id, comment) {
        return newApiInstance.post('tech/consultation-comment', {id: id, comment: comment})
            .then(response => {
                return response.data
            });
    },
    createTechConsultation(request) {
        return newApiInstance.post('tech/consultation-new', request)
            .then(response => {
                return response.data
            });
    }
}
export const manufactureAPI = {
    setManufactureComment(request) {
        return newApiInstance.post('manufacture/comment', request)
            .then(response => {
                return response.data
            });
    }
}
export const productionAPI = {
    getAssemblersList(){
        return newApiInstance.get('manufacture/assemblers-list')
            .then(response => {
                return response.data
            });
    },
    getProducesModulesList(params){
        return newApiInstance.get('manufacture/get-produced-modules', params)
            .then(response => {
                return response.data
            });
    },
    getDelistedItemsList(){
      return newApiInstance.get('warehouse/delisted-items')
            .then(response => {
                return response.data
            });
    },
    getDelistingReasons() {
        return newApiInstance.get('warehouse/delisting-reasons-list')
            .then(response => {
                return response.data
            });
    },
    getSection() {
        return instance.get('section')
            .then(response => {
                return response.data
            });
    },
    getSectionNew() {
        return newApiInstance.get('components/get-storage-sections-list')
            .then(response => {
                return response.data
            });
    },
    getNewModuleFromProduction() {
        return instance.get('getNewModuleFromProduction')
            .then(response => {
                return response.data
            });
    },
    getComponentFromSection(sectionId) {
        //return instance.get('component/' + sectionId)
        return newApiInstance.get('components/by-section/' + sectionId)
            .then(response => {
                return response.data
            });
    },
    getComponentForOrder() {
        return instance.get('componentForOrder')
            .then(response => {
                return response.data
            });
    },
    getlistProduction(managerID) {
        return newApiInstance.get('manufacture/production-list/' + managerID)
            .then(response => {
                return response.data
            });
    },
    /*getlistProduction() {
        return instance.get('getlistProduction')
            .then(response => {
                return response.data
            });
    },
    */
    getModulsPaid() {
        return newApiInstance.get('manufacture/moduls-paid')
            .then(response => {
                return response.data
            });
    },
    /*
    getModulsPaid() {
            return instance.get('getModulsPaid')
                .then(response => {
                    return response.data
                });
        },
    */

    getModulsPaidAllocated() {
        //return instance.get('getModulsPaidAllocated')
        return newApiInstance.get('manufacture/modules-allocated')
            .then(response => {
                return response.data
            });
    },

    getModulesSpecsWithSelfCost(request) {
        return newApiInstance.post('manufacture/modules-specs-self-cost', request)
            .then(response => {
                return response.data
            });
    },
    getModulesSpecsForAsembly(request) {
        return newApiInstance.post('manufacture/get-specs-at-chief-page', request)
            .then(response => {
                return response.data
            });
    },
    getComponentsCurrentStock(request) {
        return newApiInstance.post('components/get-components-stock-by-code', request)
            .then(response => {
                return response.data
            });
    },
    tryCloseDeficitItems(request) {
        return newApiInstance.post('manufacture/take-deficit-items-from-warehouse', request)
            .then(response => {
                return response.data
            });
    },
    checkIfCanBeProduced(request) {
        return newApiInstance.post('manufacture/check-if-can-be-produced', request)
            .then(response => {
                return response.data
            });
    },
    approveComponentMovement(request) {
        return newApiInstance.post('manufacture/approve-components-movement', request)
            .then(response => {
                return response.data
            });
    },


    /*getModulsPaidAllocated(){
        return newApiInstance.get('manufacture/modules-allocated')
        .then( response =>{
            return response.data
        });
    },*/
    setWhoMake(whoMake) {
        return instance.post('setWhoMake/' + whoMake[0] + '/' + whoMake[1] + '/' + whoMake[2])
            .then(response => {
                return response.data
            });
    },
    setWhoMakeWithSpecs(whoMake) {
        //moduleId, workerId, moduls_type_in_production
        return newApiInstance.post('manufacture/set-assembler',
            {
                'id': whoMake[0],
                'worker': whoMake[1],
                'moduls_type_in_production': whoMake[2],
                'item_code': whoMake[3],
                'misc': whoMake[4]
            }
        )
            .then(response => {
                return response.data
            });
    },
    setWhoMakeWithoutSpecs(request) {
        return newApiInstance.post('manufacture/set-assembler-without-selfcost',
            request
        )
            .then(response => {
                return response.data
            });
    },
    setSeparatorInBase(id, number1, number2, modulsTypeInProduction) {
        return newApiInstance.post('manufacture/separate-modules-in-invoice', {
            id: id,
            number1: number1,
            number2: number2,
            //modulsTypeInProduction :  modulsTypeInProduction
        })
            //    return instance.get('setSeparatorInBase?id=' + id + '&number1=' + number1 + '&number2=' + number2 + '&modulsTypeInProduction=' + modulsTypeInProduction)
            .then(response => {
                return response.data
            });
    },

    setModulsStatus(modulsStatus, moduleId, moduls_type_in_production) {
        return instance.post('setModulsStatus/' + moduleId + '/' + modulsStatus + '/' + moduls_type_in_production)
            .then(response => {
                return response.data
            });
    },
    cancelAssembly(moduleInfo) {
        return newApiInstance.post('manufacture/cancel-assembly', moduleInfo)
            .then(response => {
                return response.data
            });
    },

    changeAssembler(request) {
        return newApiInstance.post('manufacture/change-assembler', request)
            .then(response => {
                return response.data
            });
    },
    setModulsStatusNew(request) {
        return newApiInstance.post('manufacture/change-manufacture-status', request)
            .then(response => {
                return response.data
            });
    },

    setOtherProducts(id) {
        return instance.post('otherproducts/' + id)
            .then(response => {
                return response.data
            });
    },
    /*setModulsForAkon(modulsName, modulsColl, nomenclatures) {
        return instance.post('setmodulsforakon?name=' + modulsName + '&coll=' + modulsColl + '&nomenclatures=' + nomenclatures)
            .then(response => {
                return response.data
            });
    },*/
    setModulsForAkon(modulsName, modulsColl, nomenclatures) {
        return newApiInstance.post('manufacture/set-moduls-for-akon',
            {
                name: modulsName,
                coll: modulsColl,
                nomenclatures: nomenclatures
            })
            .then(response => {
                return response.data
            });
    },
    setCommentToStorageComponent(componentId, comment) {
        return instance.post('setCommentToStorageComponent?componentId=' + componentId + '&comment=' + comment)
            .then(response => {

                return response.data
            });
    },
    setComponentToSection(sectionId, nameComponent, collComponent, specificationId) {
        return newApiInstance.post('components/set-component-to-section',
            {
                'name': nameComponent,
                'coll': collComponent,
                'section': sectionId,
                'specificationId': specificationId
            })
            .then(response => {
                return response.data
            });
    },
    changeCollComponent(componentColl, personalId, addOrTakeProducts, article, subarticle) {
        //return instance.post('changeCollComponent?componentColl=' + componentColl + '&personalId=' + personalId + '&addOrTakeProducts=' + addOrTakeProducts + '&article=' + article + '&subarticle=' + subarticle)
        let data = {
            componentColl: componentColl,
            personalId: personalId,
            addOrTakeProducts: addOrTakeProducts,
            article: article,
            subarticle: subarticle
        }

        return newApiInstance.post('warehouse/delist', data)
            .then(response => {
                return response.data
            })
    },
    searchInSection(value, section, searchByCode, searchByName) {
        return newApiInstance.post('components/search',
            {
                'value': value,
                'section': section,
                'searchByCode': searchByCode,
                'searchByName': searchByName
                //'searchParam': searchParam
            })
            //return instance.post('searchinsection?value=' + value + '&section=' + section + '&searchParam=' + searchParam)
            .then(response => {
                return response.data
            });
    }
    ,
    changeStorageFromTech(modulsId, status) {
        return instance.get('changeStorageFromTech/' + modulsId + '/' + status)
            .then(response => {
                return response.data
            });
    }
    ,
    setNewTTN(data) {
        return instance.post('setNewTTN', data)
            .then(response => {
                return response.data
            });
    }
    ,

}
export const workerAPI = {
    getWorkersList() {
        return instance.get('getWorkersList')
            .then(response => {
                return response.data
            });
    },
    getWorkersListTech() {
        return instance.get('getWorkersListTech')
            .then(response => {
                return response.data
            });
    },
    getWorkersModulsOld() {
        return instance.get('workersmoduls')
            //return newApiInstance.get('manufacture/list-of-modules-in-production')
            .then(response => {
                return response.data
            });
    },
    getWorkersActualStats() {
        //return instance.get('workersmoduls')
        return newApiInstance.get('manufacture/actual-assemblers-stats')
            .then(response => {
                return response.data
            });
    },
    getWorkersModuls() {
        //return instance.get('workersmoduls')
        return newApiInstance.get('manufacture/list-of-modules-in-production')
            .then(response => {
                return response.data
            });
    },
    getWorkersModulsPerMonth(month, year) {
        //return instance.get('workersmoduls')
        return newApiInstance.get('manufacture/list-of-modules-per-month/' + year + '/' + month)
            .then(response => {
                return response.data
            });
    },

    getAssembliesForPeriod(assemblerArray, year, month) {
        return newApiInstance.post('manufacture/get-assemblies-for-period',
            {
                assemblerArray: assemblerArray,
                year: year,
                month: month
            })
            .then(response => {
                return response.data
            });
    },

    getWorkersListForTasks() {
        return instance.get('getWorkersListForTasks')
            .then(response => {
                return response.data
            });
    },
    getPersonalWorkersModuls(workerId) {
        return instance.get('workersmoduls/' + workerId)
            .then(response => {
                return response.data
            });
    },
    getWorkersData(workerId) {
        const cacheKey = `workersData_${workerId}`; // Cache key based on workerId
        const cacheExpiryKey = `workersDataExpiry_${workerId}`; // Expiry key based on workerId

        // Check if data exists in localStorage and is not expired
        const cachedData = localStorage.getItem(cacheKey);
        const cacheExpiry = localStorage.getItem(cacheExpiryKey);

        if (cachedData && cacheExpiry && new Date().getTime() < cacheExpiry) {
            // Return cached data if it's still valid
            return Promise.resolve(JSON.parse(cachedData));
        }

        // Fetch from API if no valid cache
        return instance.get(`getWorkersData/${workerId}`)
            .then(response => {
                const data = response.data;

                // Save response to localStorage with expiry timestamp (1 hour = 3600000ms)
                localStorage.setItem(cacheKey, JSON.stringify(data));
                localStorage.setItem(cacheExpiryKey, new Date().getTime() + (3600000 * 6)); // 6 hours from now

                return data;
            });
    },

}
export const loginAPI = {
    getSideMenuList() {
        return newApiInstance.get('user/fast-menu/personal-list')
            .then(response => {
                return response//.data
            })
    },
    removeSideMenuItem(request) {
        return newApiInstance.post('user/fast-menu/delete', request)
            .then(response => {
                return response.data
            });
    },
    addSideMenuItem(request) {
        return newApiInstance.post('user/fast-menu/add', request)
            .then(response => {
                return response.data
            });
    },
    login(request) {
        return newApiInstance.post('login', request)
            .then(response => {
                return response.data
            });
    },
    forgotPassword(email) {
        return newApiInstance.post('user/password/forgot', {email: email})
            .then(response => {
                return response.data
            });
    },
    logout() {
        return newApiInstance.post('logout')
            .then(response => {
                return response.data
            });
    },
    changePassword(request) {
        return newApiInstance.post('user/password/change', request)
            .then(response => {
                return response.data
            });
    },
    currentSessionsList(currentUserID) {
        return newApiInstance.get('user/current-sessions?currentUserID=' + currentUserID)
            .then(response => {
                return response.data
            });
    },
    checkAuth() {
        return instance.get('checkAuth')
            .then(response => {
                return response.data
            });
    }

}
export const dashboardAPI = {
    saveNewDashboard() {
        return newApiInstance.post('dashboard-new')
            .then(response => {
                return response.data
            });
    },
    //preset dashboards
    getWarehouseData(force = false) {
        return newApiInstance.get('dashboards/warehouse', force ? {params: {refresh: true}} : {})
            .then(response => {
                return response.data
            });
    },
    getSalesData(filters) {
        return newApiInstance.post('sales-funnel', filters)
            .then(response => response.data)
            .catch(error => {
                toast.error('Error fetching funnel data' + error);
                throw error;
            });
    },
};

export const companyAPI = {
    getFinalCompanyToKpinvoice(request) {
        return newApiInstance.post('company/get-final-company-to-kp-invoice', request)
            .then(response => {
                return response.data
            });
    },
    setFinalCompanyToKpinvoice(request) {
        return newApiInstance.post('company/set-final-company-to-kp-invoice', request)
            .then(response => {
                return response.data
            });
    },

    async getCompany() {
        const cacheKey = 'companyData';
        const cacheExpiryKey = 'companyExpiry';

        const cachedData = localStorage.getItem(cacheKey);
        const cacheExpiry = localStorage.getItem(cacheExpiryKey);

        if (cachedData && cacheExpiry && new Date().getTime() < cacheExpiry) {
            return JSON.parse(cachedData);
        }

        const response = await instance.get('getCompany');
        const data = response.data;

        localStorage.setItem(cacheKey, JSON.stringify(data));
        localStorage.setItem(cacheExpiryKey, new Date().getTime() + 3600000); // 1 hour from now

        return data;
    },

    getIndustriesList: async () => {
        return await newApiInstance.get('miscellaneous/industries-list')
            .then(response => {
                return response.data
            });
    },
    updateUseCase(request) {
        return newApiInstance.post('description/update', request)
            .then(response => {
                return response.data
            });
    },
    getUseCases(caseID, type) {
        return newApiInstance.get('description/get/' + caseID + '/' + type)
            .then(response => {
                return response.data
            });
    },
    getCompanyAgents(companyID) {
        return newApiInstance.get('company/contact/list/' + (companyID ?? 0))
            .then(response => {
                if (response?.data?.status !== 1) {
                    toast.error('Ошибка при получении списка контактов компании');
                    return null;
                }
                return response.data
            });
    },
    addNewMessageToOrder(request) {
        return newApiInstance.post('description/add', request)
            .then(response => {
                return response.data
            });
    },
    deleteFile(request) {
        return newApiInstance.post('description/delete-file', request)
            .then(response => {
                return response.data
            });
    },

    async getCompanyListWithCategoriesAndIndustries(request) {
        const response1 = await newApiInstance.post('company/list/with-categories', request);
        const data1 = response1.data;
        return data1;

        //

        const cacheKey = 'companyListWithCategoriesAndIndustriesData';
        const cacheExpiryKey = 'companyListWithCategoriesAndIndustriesExpiry';

        const cachedData = localStorage.getItem(cacheKey);
        const cacheExpiry = localStorage.getItem(cacheExpiryKey);

        if (cachedData && cacheExpiry && new Date().getTime() < cacheExpiry) {
            return JSON.parse(cachedData);
        }

        const response = await newApiInstance.post('company/list/with-categories');
        const data = response.data;

        localStorage.setItem(cacheKey, JSON.stringify(data));
        localStorage.setItem(cacheExpiryKey, new Date().getTime() + 3600000); // 1 hour from now

        return data;
    },


    getUnhandledCompanies() {
        return newApiInstance.get('company/unhandled-list')
            .then(response => {
                return response.data
            });
    },
    getFilterCategoryCompanies() {
        return newApiInstance.get('company/unhandled-list')
            .then(response => {
                return response.data
            });
    },
    getRelatedCompaniesTags(companiesIDlist) {
        return newApiInstance.post('company/tag/list-by-company-id', { companyIDs: companiesIDlist });
    },
    getCompanyProfile(request) {
        return newApiInstance.post('company/profile', request)
            .then(response => {
                return response.data
            });
    },
    unbindCompanyPartner(request) {
        return newApiInstance.post('company/partner/unbind', request)
            .then(response => {
                return response.data
            });
    },
    unbindTag(request) {
        return newApiInstance.post('company/tag/unbind', request)
            .then(response => {
                return response.data
            });
    },
    unbindMainManager(request) {
        return newApiInstance.post('company/manager/delete', request)
            .then(response => {
                return response.data
            });
    },
    bindSupplier(request) {
        return newApiInstance.post('company/supplier/bind', request)
            .then(response => {
                return response.data
            });
    },
    bindTag(request) {
        return newApiInstance.post('company/tag/bind', request)
            .then(response => {
                return response.data
            });
    },

    bindNewCategory(request) {
        return newApiInstance.post('company/profile/new-category/bind', request)
            .then(response => {
                return response.data
            });
    },
    unbindNewCategory(request) {
        return newApiInstance.post('company/profile/new-category/unbind', request)
            .then(response => {
                return response.data
            });
    },
    bindNewIndustry(request) {
        return newApiInstance.post('company/profile/new-industry/bind', request)
            .then(response => {
                return response.data
            });
    },
    unbindNewIndustry(request) {
        return newApiInstance.post('company/profile/new-industry/unbind', request)
            .then(response => {
                return response.data
            });
    },

    unbindSupplier(request) {
        return newApiInstance.post('company/supplier/unbind', request)
            .then(response => {
                return response.data
            });
    },
    setCompanyEdrpou(request) {
        return newApiInstance.post('company/edrpou/add', request)
            .then(response => {
                return response.data
            });
    },
    setCompanyRegion(request) {
        return newApiInstance.post('company/profile/region/add', request)
            .then(response => {
                return response.data
            });
    },
    setCompanyCity(request) {
        return newApiInstance.post('company/profile/city/save', request)
            .then(response => {
                return response.data
            });
    },
    setCompanyName(request) {
        return newApiInstance.post('company/name-edit', request)
            .then(response => {
                return response.data
            });
    },
    setCompanySite(request) {
        return newApiInstance.post('company/profile/site', request)
            .then(response => {
                return response.data
            });
    },
    setCompanyAkonName(request) {
        return newApiInstance.post('company/name-akon-edit', request)
            .then(response => {
                return response.data
            });
    },
    editCompanyDiscount(request) {
        return newApiInstance.post('company/profile/discount', request)
            .then(response => {
                return response.data
            });
    },
    setNewCompanyMessage(request) {
        return newApiInstance.post('company/messages/new', request,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then(response => {
                return response.data
            });
    },
    addNewContact(request) {
        return newApiInstance.post('company/contact/new', request)
            .then(response => {
                return response.data
            });
    },
    deleteCompanyAgent(request) {
        return newApiInstance.post('company/contact/delete', request)
            .then(response => {
                return response.data
            });
    },
    setAgentSubscription(request) {
        return newApiInstance.post('company/contact/subscription', request)
            .then(response => {
                return response.data
            });
    },
    updateCompanyAgent(request) {
        return newApiInstance.post('company/contact/update', request)
            .then(response => {
                return response.data
            });
    },
    setCompanyMessage(request) {
        return newApiInstance.post('company/messages/update', request)
            .then(response => {
                return response.data
            });
    },
    deleteMessageFromAgent(request) {
        return newApiInstance.post('company/messages/soft-delete', request)
            .then(response => {
                return response.data
            });
    },
    editCompanyManager1(request) {
        return newApiInstance.post('company/manager/add', request)
            .then(response => {
                return response.data
            });
    },
    setCompanyManager2(request) {
        return newApiInstance.post('company/another-manager/propose', request)
            .then(response => {
                return response.data
            });
    },
    setCompanyToPlanner(request) {
        return newApiInstance.post('company/profile/to-planner', request)
            .then(response => {
                return response.data
            });
    },
    getCompanyList(request) {
        return newApiInstance.get('company/list/filtered', request)
            .then(response => {
                return response.data
            });
    },
    getSegmentationsList(request) {
        return newApiInstance.get('miscellaneous/segmentation-list', request)
            .then(response => {
                return response.data
            });
    },
    getClientSegmentationList(request) {
        return newApiInstance.get('miscellaneous/client-segmentation-list', request)
            .then(response => {
                return response.data
            });
    },
    getRegionsList(request) {
        return newApiInstance.get('miscellaneous/regions-list', request)
            .then(response => {
                return response.data
            });
    },
    getCityList(request) {
        return newApiInstance.get('miscellaneous/city-list/' + request)
            .then(response => {
                return response.data
            });
    },
    addCompanyPartner(request) {
        return newApiInstance.post('company/profile/partner', request)
            .then(response => {
                return response.data
            });
    },
    setClientCompanySegmentation(request) {
        return newApiInstance.post('company/profile/client-segmentation', request)
            .then(response => {
                return response.data
            });
    },
    setCompanySegmentation(request) {
        return newApiInstance.post('company/profile/segmentation-1', request)
            .then(response => {
                return response.data
            });
    },
    setCompany2Segmentation(request) {
        return newApiInstance.post('company/profile/segmentation-2', request)
            .then(response => {
                return response.data
            });
    },
    editCompanyComment(request) {
        return newApiInstance.post('company/profile/comment', request)
            .then(response => {
                return response.data
            });
    },
    setCompanyDeliveryAddress(request) {
        return newApiInstance.post('company/profile/delivery', request)
            .then(response => {
                return response.data
            });
    },
    setCompanyContract(request) {
        return newApiInstance.post('company/contract', request)
            .then(response => {
                return response.data
            });
    },
    getAllAgents() {
        const cacheKey = 'agentsData';
        const cacheExpiryKey = 'agentsExpiry';

        // Check if data exists in localStorage and is not expired
        const cachedData = localStorage.getItem(cacheKey);
        const cacheExpiry = localStorage.getItem(cacheExpiryKey);

        if (cachedData && cacheExpiry && new Date().getTime() < cacheExpiry) {
            // Return cached data if it's still valid
            return Promise.resolve(JSON.parse(cachedData));
        }

        // Fetch from API if no valid cache
        return instance.get('getAllAgents')
            .then(response => {
                const data = response.data;

                // Save response to localStorage with expiry timestamp (1 hour = 3600000ms)
                localStorage.setItem(cacheKey, JSON.stringify(data));
                localStorage.setItem(cacheExpiryKey, new Date().getTime() + 3600000); // 1 hour from now

                return data;
            });
    },

    getAgentsPersonalCompany(id) {
        return instance.get('getAgentsPersonalCompany/' + id)
            .then(response => {
                return response.data
            });
    },
    setNewCompanyAgent(array) {
        return instance.post('setNewCompanyAgent', array)
            .then(response => {
                return response.data
            });
    },
    changeCompanyAgent(array) {
        return instance.post('changeCompanyAgent', array)
            .then(response => {
                return response.data
            });
    },
    addNewCompany(array) {
        return instance.post('addNewCompany', array)
            .then(response => {
                //debugger
                return response.data
            });
    },
    getCompanyByEdrpou(edrpou) {
        return newApiInstance.get('company/by-edrpou/' + edrpou)
            .then(response => {
                //debugger
                return response.data
            });
    },
}

export const accessoriesAPI = {
    getAccessoriesList() {
        return instance.get('getAccessoriesList')
            .then(response => {
                return response.data
            });
    }
}

export const invoiceAPI = {
    getInvoice(params) {
        return instance.get('invoice/' + params)
            .then(response => {
                return response.data
            });
    }
}
export const specificationAPI = {
    getSpecificationList(id) {
        return instance.get('getSpecificationList/' + id)
            .then(response => {
                return response.data
            });
    },
    getNomenclaturesData(id) {
        return instance.get('getNomenclaturesData/' + id)
            .then(response => {
                return response.data
            });
    },
    getALLNomenclatures() {
        return newApiInstance.get('bom/nomenclatures-list')
            .then(response => {
                return response.data
            });
    },
    uploadXLSXFile(fileInfo) {
        return newApiInstance.post('bom/parse/xlsx', fileInfo)
            .then(response => {
                return response.data
            });
    },
    setNewSpecification(array) {
        return newApiInstance.post('bom/set-new-specs', array)
            .then(response => {
                return response.data
            });
    },
    getSpecificationsElements(number, currentVersion) {
        return newApiInstance.get('bom/detailed-info/' + number + '/' + currentVersion ?? 0)
            .then(response => {
                //debugger
                return response.data
            });
    },
    changeSpecificationProps(newProps) {
        return newApiInstance.post('bom/detailed-info/update', newProps)
            .then(response => {
                //debugger
                return response.data
            });
    },
    getVersionSpecifications(number) {
        return newApiInstance.get('bom/specification-versions/' + number)
            .then(response => {
                return response.data
            });
    }
}
export const adminAPI = {
    receiveVATInfo(request){
        return newApiInstance.get('admin/vat-info', request)
            .then(response => {
                return response.data
            });
    },
    updateAdditionalVAT(request){
       return newApiInstance.post('admin/vat-edit', request)
           .then(response=>{
                return response.data
           })
    },
    deleteAdditionalVAT(request){
       return newApiInstance.post('admin/vat-delete', request)
           .then(response=>{
                return response.data
           })
    },
    saveAdditionalVAT(request){
        return newApiInstance.post('admin/vat-info', request)
            .then(response => {
                return response.data
            })
    },
    requestAIUseCaseFullfill(request) {
        return newApiInstance.post('use-cases/ai-generate', request)
            .then(response => {
                return response.data
            });
    },
    getAllUseCases() {
        return newApiInstance.get('use-cases/all')
            .then(response => {
                return response.data
            });
    },
    getUseCaseByID(id) {
        return newApiInstance.get('use-cases/' + id)
            .then(response => {
                return response.data
            });
    },
    updateUseCase(id, data) {
        return newApiInstance.post('use-cases/edit/' + id, data)
            .then(response => {
                return response.data
            });
    },
    getAllUseCasesStats() {
        return newApiInstance.get('use-cases/statistics')
            .then(response => {
                return response.data
            });
    },
    getSelfcostByNumFastPanel(request) {
        return newApiInstance.post('admin/fast-self-cost', request)
            .then(response => {
                return response.data
            });
    },
    getCountEmptySelfcost(request) {
        return newApiInstance.post('selfcost/count-items-without-full-selfcost', request)
            .then(response => {
                return response.data
            });
    },
    getListEmptySelfcost(request) {
        return newApiInstance.post('selfcost/list-items-without-full-selfcost', request)
            .then(response => {
                return response.data
            });
    },


    getAllComponentsFromAllPaidNomenclatures() {
        return instance.get('getAllComponentsFromAllPaidNomenclatures')
            .then(response => {
                return response.data
            });
    },
    getListWithCompanyUnpin() {
        return instance.get('getListWithCompanyUnpin')
            .then(response => {
                return response.data
            });
    },
    getAllPaidCheckForMonth(month, year) {
        return instance.get('getAllPaidCheckForMonth/' + month + '/' + year)
            .then(response => {
                return response.data
            });
    },
    setNewDateManagerInScheta(id, date, type) {
        return instance.get('setNewDateManagerInScheta/' + id + '/' + date + '/' + type)
            .then(response => {
                return response.data
            });
    },
    getSumSubentriesGrupMonth(year) {
        return instance.get('getSumSubentriesGrupMonth/' + year)
            .then(response => {
                return response.data
            });
    },
    getSumAllPaidCheckForMonth(month, year) {
        return instance.get('getSumAllPaidCheckForMonth/' + month + '/' + year)
            .then(response => {
                return response.data
            });
    },
    getSumAllPaidCheckForMonthRu(month, year) {
        return instance.get('getSumAllPaidCheckForMonthRu/' + month + '/' + year)
            .then(response => {

                return response.data
            });
    },
    getAllArticles() {
        return instance.get('getAllArticles')
            .then(response => {
                return response.data
            });
    },
    getSubentries(idArticles) {
        return instance.get('getSubentries/' + idArticles)
            .then(response => {
                return response.data
            });
    },
    addNewCash(newCash) {
        return instance.post('addNewCash', newCash)
            .then(response => {
                return response.data
            });
    },
    getMoneyCash() {
        return instance.get('getMoneyCash')
            .then(response => {
                return response.data
            });
    },
    getCashFlowForMonth(month) {
        return instance.get('getCashFlowForMonth/' + month)
            .then(response => {
                return response.data
            });
    },
    getSumCashFlowForMonth(month) {
        return instance.get('getSumCashFlowForMonth/' + month)
            .then(response => {
                return response.data
            });
    },
    getAllPaidCostsForMonthFromSubetries(id, month, year) {
        return instance.get('getAllPaidCostsForMonthFromSubetries/' + id + '/' + month + '/' + year)
            .then(response => {
                return response.data
            });
    },
    getAllPaidCostsListForMonthFromSubetries(month, year) {
        return instance.get('getAllPaidCostsListForMonthFromSubetries/' + month + '/' + year)
            .then(response => {
                return response.data
            });
    },
    getSumPaidCostsForMonth(month, year) {
        return instance.get('getSumPaidCostsForMonth/' + month + '/' + year)
            .then(response => {
                return response.data
            });
    },
    getSumAndNameSubetriesForMonth(id, month, year) {
        return instance.get('getSumAndNameSubetriesForMonth/' + id + '/' + month + '/' + year)
            .then(response => {
                return response.data
            });
    },
    setNewMoneyDelivery(sum, subentries) {
        return instance.get('setNewMoneyDelivery/' + sum + '/' + subentries)
            .then(response => {
                return response.data
            });
    },
    getSumAndNameArticlesForMonth(month, year) {
        return instance.get('getSumAndNameArticlesForMonth/' + month + '/' + year)
            .then(response => {
                return response.data
            });
    },
    getMoneyDelivery() {
        return instance.get('getMoneyDelivery')
            .then(response => {
                return response.data
            });
    },
    getAllArticlesSubentriesForMoneyDelivery() {
        return instance.get('getAllArticlesSubentriesForMoneyDelivery')
            .then(response => {
                return response.data
            });
    },
    getSpecificationListAdmin(id) {
        return instance.get('getSpecificationListAdmin/' + id)
            .then(response => {
                return response.data
            });
    },
    getSentInvoicesForMonth(month) {
        return instance.get('getSentInvoicesForMonth/' + month)
            .then(response => {
                return response.data
            });
    },
    getSumSentInvoicesForMonth(month) {
        return instance.get('getSumSentInvoicesForMonth/' + month)
            .then(response => {
                return response.data
            });
    },
    getSentInvoiceForMonthWithDateManager(month, year, dayStart, dayEnd) {
        return instance.get('getSentInvoiceForMonthWithDateManager/' + month + '/' + year + '/' + dayStart + '/' + dayEnd)
            .then(response => {
                return response.data
            });
    },
    getSumSentInvoicesForYear() {
        return instance.get('getSumSentInvoicesForYear')
            .then(response => {
                return response.data
            });
    },
    getReceivablesAdmin() {
        return instance.get('getReceivablesAdmin')
            .then(response => {
                return response.data
            });
    },
    getAllBomNomenclatures() {
        return instance.get('getAllBomNomenclatures')
            .then(response => {
                return response.data
            });
    },
    getAllComponentFromNomenclatures() {
        return instance.get('getAllComponentFromNomenclatures')
            .then(response => {
                return response.data
            });
    },
    getStorageAndAveragePrice() {
        return instance.get('getStorageAndAveragePrice')
            .then(response => {
                return response.data
            });
    },
    getAccountsPayable() {
        return instance.get('getAccountsPayable')
            .then(response => {
                return response.data
            });
    },
    addAccountsPayable(idCompany, coll) {
        return instance.get('addAccountsPayable/' + idCompany + '/' + coll)
            .then(response => {
                return response.data
            });
    },
    deleteAccountsPayable(id) {
        return instance.get('deleteAccountsPayable/' + id)
            .then(response => {
                return response.data
            });
    },
    getCourse() {
        const cacheKey = 'courseData';
        const cacheExpiryKey = 'courseExpiry';

        // Check if data exists in localStorage and is not expired
        const cachedData = localStorage.getItem(cacheKey);
        const cacheExpiry = localStorage.getItem(cacheExpiryKey);

        if (cachedData && cacheExpiry && new Date().getTime() < cacheExpiry) {
            // Return cached data if it's still valid
            return Promise.resolve(JSON.parse(cachedData));
        }

        // Fetch from API if no valid cache
        return instance.get('getCourse')
            .then(response => {
                const data = response.data;

                // Save response to localStorage with expiry timestamp (6 hours = 6 * 3600000ms)
                localStorage.setItem(cacheKey, JSON.stringify(data));
                localStorage.setItem(cacheExpiryKey, new Date().getTime() + (6 * 3600000)); // 6 hours from now

                return data;
            });
    },

    getCvoMoney(month) {
        return instance.get('getCvoMoney/' + month)
            .then(response => {
                return response.data
            });
    },
    getAllActivityAdmin(idManager, dateStart, dateEnd) {
        return instance.get('getAllActivityAdmin/' + idManager + '/' + dateStart + '/' + dateEnd)
            .then(response => {
                return response.data
            });
    },
    // getAllMessageFromManager(date){
    //     return instance.post('getAllMessageFromManager',date)
    //     .then( response =>{
    //         return response.data
    //     });
    // }
}
export const salesAPI = {
    getNewCompanyYearlyStats(managerID) {
        return newApiInstance.get('all-company-purchases-sum/' + managerID)
            .then(response => {
                return response.data
            });
    },

    getRelatedPaymentForYearMonth(request) {
        return newApiInstance.post('manager/payment-story', request)
            .then(response => {
                return response.data
            });
    },

    changeDealManagerPercentage(request){
      return newApiInstance.post('manager/change-payment-percentage', request)
          .then(response => {
              return response.data
          })
    },

    getAllModulesNomenclatures(id, type) {
        const cacheKey = 'modulesNomenclatureData';
        const cacheExpiryKey = 'modulesNomenclatureExpiry';

        // Check if data exists in localStorage and is not expired
        const cachedData = localStorage.getItem(cacheKey);
        const cacheExpiry = localStorage.getItem(cacheExpiryKey);

        if (cachedData && cacheExpiry && new Date().getTime() < cacheExpiry) {
            // Return cached data if it's still valid
            return Promise.resolve(JSON.parse(cachedData));
        }

        // Fetch from API if no valid cache
        return newApiInstance.get('manufacture/get-modules-list')
            .then(response => {
                const data = response.data;

                // Save response to localStorage with expiry timestamp (1 hour = 3600000ms)
                localStorage.setItem(cacheKey, JSON.stringify(data));
                localStorage.setItem(cacheExpiryKey, new Date().getTime() + 300000); // 5 min from now

                return data;
            });
    },


    unbindCompanyPartner(request) {
        return newApiInstance.post('company/partner/unbind', request)
            .then(response => {
                return response.data
            });
    },
    getAllCompanySegmentation() {
        return instance.get('getAllCompanySegmentation')
            .then(response => {
                return response.data
            });
    },
    getAllCompanyPlanerInThisDate(date, id) {
        return instance.get('getAllCompanyPlanerInThisDate/' + date + '/' + id)
            .then(response => {
                return response.data
            });
    },
    getStatusKp() {
        const cacheKey = 'statusKpData';
        const cacheExpiryKey = 'statusKpExpiry';

        // Check if data exists in localStorage and is not expired
        const cachedData = localStorage.getItem(cacheKey);
        const cacheExpiry = localStorage.getItem(cacheExpiryKey);

        if (cachedData && cacheExpiry && new Date().getTime() < cacheExpiry) {
            // Return cached data if it's still valid
            return Promise.resolve(JSON.parse(cachedData));
        }

        // Fetch from API if no valid cache
        return newApiInstance.get('kp/getStatusKp')
            .then(response => {
                const data = response.data;

                // Save response to localStorage with expiry timestamp (1 hour = 3600000ms)
                localStorage.setItem(cacheKey, JSON.stringify(data));
                localStorage.setItem(cacheExpiryKey, new Date().getTime() + 3600000); // 1 hour from now

                return data;
            });
    },

    addNewMeeting(array) {
        return instance.post('addNewMeeting', array)
            .then(response => {
                //debugger
                return response.data
            });
    },
    acceptCompany2ndManager(request) {
        return newApiInstance.post('company/another-manager/confirm', request)
            .then(response => {
                //debugger
                return response.data
            });
    },
    rejectCompany2ndManager(request) {
        return newApiInstance.post('company/another-manager/reject', request)
            .then(response => {
                return response.data
            });
    },
    changeDateReminderKp(id, date, type) {
        return instance.get('changeDateReminderKp/' + id + '/' + date + '/' + type)
            .then(response => {
                return response.data
            });
    },
    setCompanyToPlanner(requestArr) {

        return newApiInstance.post('planner/setCompanyToPlanner', requestArr)
            .then(response => {
                return response.data
            });
    },
    /*   setCompanyToPlanner(id, date){

        return instance.get('setCompanyToPlanner/'+id+'/'+date)
        .then( response =>{

            return response.data
        });
    },*/
    deleteMeeting(id) {
        return instance.get('deleteMeeting/' + id)
            .then(response => {
                return response.data
            });
    },
    deleteCompanyFromPlanner(id) {
        return instance.get('deleteCompanyFromPlanner/' + id)
            .then(response => {
                return response.data
            });
    },
    changeMeetingText(id, text) {
        return instance.get('changeMeetingText/' + id + '/' + text)
            .then(response => {
                return response.data
            });
    },
    editCompanyStatus(obj) {
        return newApiInstance.post('planner/update-status', obj)
            .then(response => {
                return response.data
            });
    },
    closeMeeting(id, text) {
        return instance.get('сloseMeeting/' + id + '/' + text)
            .then(response => {
                return response.data
            });
    },
    getAllCompanyWithManager(id) {
        return instance.get('getAllCompanyWithManager/' + id)
            .then(response => {
                return response.data
            });
    },
    getAllCompanyOutdatedWithManager(id) {
        console.log('getAllCompanyOutdatedWithManager', id)
        return newApiInstance.get('company/outdated/' + id)
            .then(response => {
                return response.data
            });
    },
    getAllSharedCompanyWithManager(id) {
        return newApiInstance.get('/company/another-manager/list/' + id)
            .then(response => {
                return response.data
            });
    },
    statusWorkingKp(array) {
        return newApiInstance.post('kp/convert-to-invoice', array)
            .then(response => {
                return response.data
            });
    },
    /*
        statusWorkingKp(array) {
            return instance.post('statusWorkingKp', array)
                .then(response => {
                    return response.data
                });
        },
    */
    changeNumberReturnFromTest(id, newNumber) {
        return instance.get('changeNumberReturnFromTest/' + id + '/' + newNumber)
            .then(response => {
                return response.data
            });
    },
    getReturnFromTest() {
        return instance.get('getReturnFromTest')
            .then(response => {
                return response.data
            });
    },
    getAllCompanyCategories() {
        return instance.get('getAllCompanyCategories')
            .then(response => {
                return response.data
            });
    },
    getCompanyInSegmentationCategories(array) {
        return instance.post('getCompanyInSegmentationCategories', array)
            .then(response => {
                return response.data
            });
    },
    getPayModulsInCompany(company) {
        return instance.post('getPayModulsInCompany', company)
            .then(response => {
                return response.data
            });
    },
    setCompanyNotActive(id) {
        return instance.get('setCompanyNotActive/' + id)
            .then(response => {

                return response.data
            });
    },
    getAllMainSupplier() {
        return instance.get('getAllMainSupplier')
            .then(response => {

                return response.data
            });
    },
    setNewMainSupplier(name) {
        return instance.get('setNewMainSupplier/' + name)
            .then(response => {
                return response.data
            });
    },
    changeMainSupplier(change) {
        return instance.post('changeMainSupplier', change)
            .then(response => {
                return response.data
            });
    },
    getMainSupplierList() {
        return instance.get('getMainSupplierList')
            .then(response => {
                return response.data
            });
    },
    getEmailCompanyAgents() {
        return instance.get('getEmailCompanyAgents')
            .then(response => {
                return response.data
            });
    },
    getCompanyWithRegion() {
        return instance.get('getCompanyWithRegion')
            .then(response => {
                return response.data
            });
    },
    getAllCompanyRegion() {
        return instance.get('getAllCompanyRegion')
            .then(response => {
                return response.data
            });
    },
    getFiltrWants(personalId) {
        return instance.get('getFiltrWants/' + personalId)
            .then(response => {
                return response.data
            });
    },
    getCompanyWithFiltrWants(id, managerId) {
        return instance.get('getCompanyWithFiltrWants/' + id + '/' + managerId)
            .then(response => {
                return response.data
            });
    },
    setShowFieldWants(id, blocksId, personalId) {
        return instance.get('setShowFieldWants/' + id + '/' + blocksId + '/' + personalId)
            .then(response => {
                return response.data
            });
    },
    getKp(id, dateStart, dateEnd) {
        return newApiInstance.get('kp/getKp/' + id + '/' + dateStart + '/' + dateEnd)
            .then(response => {
                return response.data
            });
    },
    getKpPlan(id, dateStart, dateEnd) {
        return instance.get('getKpPlan/' + id + '/' + dateStart + '/' + dateEnd)
            .then(response => {
                return response.data
            });
    },
    getSumKpForMonth(id) {
        return instance.get('getSumKpForMonth/' + id)
            .then(response => {
                return response.data
            });
    },
    getSumKpPaidForMonth(id) {
        return instance.get('getSumKpPaidForMonth/' + id)
            .then(response => {
                return response.data
            });
    },
    getSumKpForMonthGroupStatus(id) {
        return instance.get('getSumKpForMonthGroupStatus/' + id)
            .then(response => {
                return response.data
            });
    },
    getAllMeetingWithManager(id) {
        // debugger
        return instance.get('getAllMeetingWithManager/' + id)
            .then(response => {
                return response.data
            });
    },
    setPaidInKp(kpId, numberNewCheck, type) {
        return instance.get('setPaidInKp/' + kpId + '/' + numberNewCheck + '/' + type)
            .then(response => {
                return response.data
            });
    },
    addNewFiltrBlock(array) {
        return instance.post('addNewFiltrBlock', array)
            .then(response => {
                return response.data
            });
    },
    getFiltrBlock(id) {
        return instance.get('getFiltrBlock/' + id)
            .then(response => {
                return response.data
            });
    },
    deleteFiltrBlock(id) {
        return instance.get('deleteFiltrBlock/' + id)
            .then(response => {
                return response.data
            });
    },
    getCheckTest(type) {
        return instance.get('getCheckTest/' + type)
            .then(response => {
                return response.data
            });
    },
    getCheckTestModuls(type) {
        return instance.get('getCheckTestModuls/' + type)
            .then(response => {
                return response.data
            });
    },
    getCountOverdueCheckTest(type) {
        return instance.get('getCountOverdueCheckTest/' + type)
            .then(response => {
                return response.data
            });
    },
    changeDateReminder(idCheckTest, newDate) {
        return instance.get('changeDateReminder/' + idCheckTest + '/' + newDate)
            .then(response => {
                return response.data
            });
    },
    buyModulsTest(idCheckTest, newData) {
        return instance.get('buyModulsTest/' + idCheckTest + '/' + newData)
            .then(response => {
                return response.data
            });
    },
    returnModulsTest(idCheckTest, newData) {
        return instance.get('returnModulsTest/' + idCheckTest + '/' + newData)
            .then(response => {
                return response.data

            });
    },
    getReturnModulsTest(idCheckTest) {
        return instance.get('getReturnModulsTest/' + idCheckTest)
            .then(response => {
                return response.data
            });
    },
    changeCommentModulsTest(idCheckTest, comment) {
        return instance.get('changeCommentModulsTest/' + idCheckTest + '/' + comment)
            .then(response => {
                return response.data
            });
    },
    getSumMonthKpPlan(idManager) {
        return instance.get('getSumMonthKpPlan/' + idManager)
            .then(response => {
                return response.data
            });
    },
    getAllDistributionNomenclatures(idManager) {
        return instance.get('getAllDistributionNomenclatures/' + idManager)
            .then(response => {
                return response.data
            });
    },
    getAllDistribution(idManager) {
        return instance.get('getAllDistribution/' + idManager)
            .then(response => {
                return response.data
            });
    },
    addNewDistribution(array) {
        return instance.post('addNewDistribution', array)
            .then(response => {
                return response.data
            });
    },
    addNewNom(nom, idDistribution, personalId) {
        return instance.get('addNewNom/' + nom + '/' + idDistribution + '/' + personalId)
            .then(response => {
                return response.data
            });
    },
    addNewDistributionStatistics(nom, idDistribution, type) {
        return instance.get('addNewDistributionStatistics/' + nom + '/' + idDistribution + '/' + type)
            .then(response => {
                return response.data
            });
    },
    changeDateDistribution(nom, idDistribution) {
        return instance.get('changeDateDistribution/' + nom + '/' + idDistribution)
            .then(response => {
                return response.data
            });
    },
    changeNameDistribution(nom, idDistribution) {
        return instance.get('changeNameDistribution/' + nom + '/' + idDistribution)
            .then(response => {
                return response.data
            });
    },
    deleteDistribution(idDistribution) {
        return instance.get('deleteDistribution/' + idDistribution)
            .then(response => {
                return response.data
            });
    },
}
export const storageAPI = {

    getStorageModulsReady() {
        return instance.get('getStorageModulsReady')
            .then(response => {
                return response.data
            });
    },
    changeStorageComponentName(request) {
        return newApiInstance.post('components/change-name', request)
            .then(response => {
                return response.data
            });
    },
    changeComponentSection(componentId, sectionId) {
        return instance.get('changeComponentSection?componentId=' + componentId + '&sectionId=' + sectionId)
            .then(response => {
                return response.data
            });
    },
    getAllComponentsFromStorage() {
        return newApiInstance.get('components/get-list')
            .then(response => {
                if (response.data.status === 1) {
                    window.location.reload();
                }
                return response.data;
            })
            .catch(error => {
                console.error("Error fetching components:", error);
            });
    },
    getTechStorage() {
        return instance.get('getTechStorage')
            .then(response => {
                return response.data
            });
    },
    changeMinColl(id, newValue) {
        return instance.get('changeMinColl/' + id + '/' + newValue)
            .then(response => {
                return response.data
            });
    }
}
export const salesPesonalAPI = {
    getAllActivity(id, dateStart, dateEnd, search) {
        return instance.get('getAllActivity/' + id + '/' + dateStart + '/' + dateEnd + '/' + search)
            .then(response => {
                return response.data
            });
    },
    addNewNomenclatures(text) {
        return instance.get('addNewNomenclatures/' + text)
            .then(response => {
                return response.data
            });
    },
}
export const modulsAPI = {
    getModulsWithComponent(componentId) {
        return newApiInstance.get('components/modules-uses-component-by-id/' + componentId)
            .then(response => {
                return response.data
            });
    },
    getStatisticsPaidFromModulesNomenclature(nomenclatures) {
        return instance.get('getStatisticsPaidFromModulesNomenclature/' + nomenclatures)
            .then(response => {
                return response.data
            });
    },
    getPersonalModuleCategory(nomenclatures) {
        return instance.get('getPersonalModuleCategory/' + nomenclatures)
            .then(response => {
                return response.data
            });
    },
    getPersonalBalancesInStock(nomenclatures) {
        return instance.get('getPersonalBalancesInStock/' + nomenclatures)
            .then(response => {
                return response.data
            });
    },
    getModulesFinanceInfo(nomenclatures) {
        return newApiInstance.get('selfcost/by-code/' + nomenclatures)
            .then(response => {
                return response.data
            });
    },

    getPersonalModuleCategoryCompany(nomenclatures, category) {
        return instance.get('getPersonalModuleCategoryCompany/' + nomenclatures + '/' + category)
            .then(response => {
                return response.data
            });
    }
}
export const personalAreaAPI = {
    getPersonalAreaSalesCheck(personalId) {
        return instance.get('getPersonalAreaSalesCheck/' + personalId)
            .then(response => {
                return response.data
            });
    },
    getPersonalAreaSalesCheckOn7Day(personalId) {
        return instance.get('getPersonalAreaSalesCheckOn7Day/' + personalId)
            .then(response => {

                return response.data
            });
    },
    getPersonalKpForPeriod(personalId) {
        return instance.get('getPersonalKpForPeriod/' + personalId)
            .then(response => {
                return response.data
            });
    },
    changeDatePlanKp(idKp, date) {
        return instance.get('changeDatePlanKp/' + idKp + "/" + date)
            .then(response => {
                return response.data
            });
    },
    setShowKp(idKp) {
        return instance.get('setShowKp/' + idKp)
            .then(response => {
                return response.data
            });
    }
}
export const privateOfficeAPI = {
    editPersonalFiltrBlockName(data) {
        return newApiInstance.post('PrivateOfficeBlocks', data)
            .then(response => {
                return response.data;
            });
    },

    getPrivateOffice(personalId) {
        return newApiInstance.get('PrivateOfficeBlocks/all/' + personalId)
            .then(response => {
                return response.data;
            });
    },

    getPrivateOfficeBlocks(personalId) {
        return newApiInstance.get('PrivateOfficeBlocks/' + personalId)
            .then(response => {
                return response.data;
            });
    },

    addNewPersonalFiltrBlock(array) {
        return instance.post('addNewPersonalFiltrBlock', array)
            .then(response => {
                return response.data;
            });
    },

    deletePersonalFiltrBlock(id, type) {
        return instance.get('deletePersonalFiltrBlock/' + id + '/' + type)
            .then(response => {
                return response.data;
            });
    },

    setShowPersonalOfficeField(id, blocksId, personalId) {
        return instance.get('setShowPersonalOfficeField/' + id + '/' + blocksId + '/' + personalId)
            .then(response => {
                return response.data;
            });
    },

    setColorBgInPersonalFilter(id, color) {
        return instance.get('setColorBgInPersonalFilter/' + id + '/' + color)
            .then(response => {
                return response.data;
            });
    },

    getCountRepairFromSite() {

        return instance.get('getCountRepairFromSite')
            .then(response => {
                return response.data;
            });
    }
}

export const techTaskAPI = {
    getTasks(idWorker) {
        return instance.get('getTasks/' + idWorker)
            .then(response => {
                return response.data
            });
    },
    getTasksComment() {
        return instance.get('getTasksComment')
            .then(response => {
                return response.data
            });
    },
    setNewTask(array) {
        return instance.post('setNewTask', array)
            .then(response => {
                return response.data
            });
    },

    addCommentForTask(array) {
        return instance.post('addCommentForTask', array)
            .then(response => {
                return response.data
            });
    },
    addNewDateInTask(id, date, status) {
        return instance.get('addNewDateInTask/' + id + "/" + date + "/" + status)
            .then(response => {
                return response.data
            });
    },
    setNewWorkerInTask(idTask, idWorker) {
        return instance.get('setNewWorkerInTask/' + idTask + "/" + idWorker)
            .then(response => {
                return response.data
            });
    },
    setTaskWithoutArchive(idTask) {
        return instance.get('setTaskWithoutArchive/' + idTask)
            .then(response => {
                return response.data
            });
    },
}
export const taskAPI = {
    getTasksAll(idWorker) {
        return instance.get('getTasksAll/' + idWorker)
            .then(response => {
                return response.data
            });
    },
    changeTextTask(array) {
        return instance.post('changeTextTask', array)
            .then(response => {
                return response.data
            });
    },
    getTasksCommentAll(idWorker) {
        return instance.get('getTasksCommentAll/' + idWorker)
            .then(response => {
                return response.data
            });
    },
    setNewTaskAll(array) {
        return instance.post('setNewTaskAll', array)
            .then(response => {
                //debugger
                return response.data
            });
    },
    addCommentForTaskAll(array) {
        return instance.post('addCommentForTaskAll', array)
            .then(response => {
                return response.data
            });
    },
    addNewDateInTaskAll(id, date, status) {
        return instance.get('addNewDateInTaskAll/' + id + "/" + date + "/" + status)
            .then(response => {
                return response.data
            });
    },
    changeDateForTask(id, date, type) {
        return instance.get('changeDateForTask/' + id + "/" + date + "/" + type)
            .then(response => {
                return response.data
            });
    },
    setNewWorkerInTaskAll(idTask, idWorker) {
        return instance.get('setNewWorkerInTaskAll/' + idTask + "/" + idWorker)
            .then(response => {
                return response.data
            });
    },
    setTaskWithoutArchiveAll(idTask) {
        return instance.get('setTaskWithoutArchiveAll/' + idTask)
            .then(response => {
                return response.data
            });
    },
    deleteTask(idTask, status) {
        return instance.get('deleteTask/' + idTask + '/' + status)
            .then(response => {
                return response.data
            });
    },
    setDateReminder(idTask, date) {
        return instance.get('setDateReminder/' + idTask + '/' + date)
            .then(response => {
                //debugger
                return response.data
            });
    },
}
export const statisticsAPI = {
    getModuleSalesStatistics(year) {
        return instance.get('getModuleSalesStatistics/' + year)
            .then(response => {
                return response.data
            });
    },
    getActiveSalesWithCompany() {
        return instance.get('getActiveSalesWithCompany')
            .then(response => {
                return response.data
            });
    },
    setModulesForYear() {
        return newApiInstance.get('stats/modules-per-year')
            .then(response => {
                return response.data
            });
    },
    getStatisticsBranch(year) {
        return instance.get('getStatisticsBranch/' + year)
            .then(response => {
                return response.data
            });
    },
    getStatisticsBranchSum(year) {
        return instance.get('getStatisticsBranchSum/' + year)
            .then(response => {
                return response.data
            });
    },
    getPaidChecksYear() {
        return instance.get('getPaidChecksYear/')
            .then(response => {
                return response.data
            });
    },
    getAverageCollModulsInCheck() {
        return instance.get('getAverageCollModulsInCheck/')
            .then(response => {
                return response.data
            });
    },
    getAverageCollPositionModulsInCheck() {
        return instance.get('getAverageCollPositionModulsInCheck/')
            .then(response => {
                return response.data
            });
    },
    getAverageCheck() {
        return instance.get('getAverageCheck/')
            .then(response => {
                return response.data
            });
    },
    getNewCustomer() {
        return instance.get('getNewCustomer/')
            .then(response => {
                return response.data
            });
    },
    getCustomerForYear() {
        return instance.get('getCustomerForYear/')
            .then(response => {
                return response.data
            });
    }
}
export const suppliersAPI = {
    getAllSuppliers() {
        return instance.get('getAllSuppliers')
            .then(response => {
                return response.data
            });
    },
    getComponentSuppliers(componentId) {
        return instance.get('getComponentSuppliers/' + componentId)
            .then(response => {
                return response.data
            });
    },
    getStorageComponentPersonalData(componentId) {
        return instance.get('getStorageComponentPersonalData/' + componentId)
            .then(response => {
                return response.data[0]
            });
    },
    getPurchasedComponents(componentId) {
        return instance.get('getPurchasedComponents/' + componentId)
            .then(response => {
                return response.data
            });
    },
    getComponentLinkForBuy(componentId) {
        return instance.get('getComponentLinkForBuy/' + componentId)
            .then(response => {
                return response.data
            });
    },
    setNewLinkForBy(companyId, link, componentId) {
        return instance.get('setNewLinkForBy?companyId=' + companyId + '&link=' + link + '&componentId=' + componentId)
            .then(response => {
                return response.data
            });
    },
    setComponentSuppliers(companyId, checked, componentId) {
        return instance.get('setComponentSuppliers?companyId=' + companyId + '&checked=' + checked + '&componentId=' + componentId)
            .then(response => {
                return response.data
            });
    }
}
export const planAPI = {
    getPlan() {
        return instance.get('getPlan')
            .then(response => {
                return response.data
            });
    },
}
export const searchAPI = {
    getUseCases(request) {
        return newApiInstance.post('use-cases', request)
            .then(response => {
                return response.data
            });
    },
}

export const checkAPI = {
    getAllInfoFromCheck(id, type) {
        return instance.get('getAllInfoFromCheck/' + id + '/' + type)
            .then(response => {
                return response.data
            });
    },
    deleteCheck(id, type) {
        return instance.get('deleteCheck/' + id + '/' + type)
            .then(response => {
                return response.data
            });
    },

    getAllModulsFromCheck(id, type) {
        return newApiInstance.get('manufacture/get-all-modules-from-check/' + id + '/' + type)
            .then(response => {
                return response.data
            });
    },
    setNewCourseAndSumCheck(array) {
        return instance.post('setNewCourseAndSumCheck', array)
            .then(response => {
                return response.data
            });
    },
    getCostPriceAllProducts() {
        return instance.get('getCostPriceAllProducts')
            .then(response => {
                return response.data
            });
    },
    setDaysManagerFromCheck(idCheck, daysManager, type) {
        return instance.get('setDaysManagerFromCheck/' + idCheck + '/' + daysManager + '/' + type)
            .then(response => {
                return response.data
            });
    },
    setPlanDateFromCheck(idCheck, datePlan, type) {
        return instance.get('setPlanDateFromCheck/' + idCheck + '/' + datePlan + '/' + type)
            .then(response => {
                return response.data
            });
    },
    setCommentForCheck(array) {
        return instance.post('setCommentForCheck', array)
            .then(response => {
                return response.data
            });
    },
    setDontShowKp(idCheck, showKp) {
        return instance.get('setDontShowKp/' + idCheck + "/" + showKp)
            .then(response => {
                return response.data
            });
    },
    changeCollModulsInCheck(id, coll) {
        return instance.get('changeCollModulsInCheck/' + id + "/" + coll)
            .then(response => {
                return response.data
            });
    },
    requestForKpStatus(idCheck, type) {
        return instance.get('requestForKpStatus/' + idCheck + "/" + type)
            .then(response => {
                return response.data
            });
    },
    getMessagesPersonalKp(id) {
        return instance.get('getMessagesPersonalKp/' + id)
            .then(response => {
                return response.data
            });
    },
    // create new KP and get its ID
    getLastIdKp(request) {
        return newApiInstance.post('kp/create', request)
            .then(response => {
                return response.data
            });
    },
    getKpById(request) {
        return newApiInstance.post('kp/load', request)
            .then(response => {
                return response.data
            });
    },
    addNewKp(array) {
        return newApiInstance.post('kp/edit', array)
            .then(response => {
                return response.data
            });
    },
    editKp(request) {
        return newApiInstance.post('kp/edit', request)
            .then(response => {
                return response.data
            });
    },
    updateKp(request) {
        return newApiInstance.post('kp/update', request)
            .then(response => {
                return response.data
            });
    },
    deleteKp(request) {
        return newApiInstance.post('kp/delete', request)
            .then(response => {
                return response.data
            });
    },
    setNewKpMessage(array) {
        return instance.post('setNewKpMessage', array)
            .then(response => {
                //debugger
                return response.data
            });
    },
}
export const repairAPI = {
    getRepair() {
        return instance.get('getRepair')
            .then(response => {
                return response.data
            });
    },
    setNewWorkerInRepair(idRepair, idWorker) {
        return instance.get('setNewWorkerInRepair/' + idRepair + '/' + idWorker)
            .then(response => {
                return response.data
            });
    },
    setTTNInRepair(idRepair, value, value2) {
        return instance.get('setTTNInRepair/' + idRepair + '/' + value + '/' + value2)
            .then(response => {
                return response.data
            });
    },
    setCollInRepair(idRepair, value) {
        return instance.get('setCollInRepair/' + idRepair + '/' + value)
            .then(response => {
                return response.data
            });
    },
    addCompanyFromRepair(idRepair, value) {
        return instance.get('addCompanyFromRepair/' + idRepair + '/' + value)
            .then(response => {
                return response.data
            });
    },
    setAssurance(idRepair, value) {
        return instance.get('setAssurance/' + idRepair + '/' + value)
            .then(response => {
                return response.data
            });
    },
    addModuleFromRepair(array) {
        return instance.post('addModuleFromRepair', array)
            .then(response => {
                return response.data
            });
    },
    setDateClose(idRepair) {
        return instance.get('setDateClose/' + idRepair)
            .then(response => {
                return response.data
            });
    },
    getPersonalRepairsList(idRepair) {
        return instance.get('getPersonalRepairsList/' + idRepair)
            .then(response => {
                return response.data
            });
    },
    setNewDefects(array) {
        return instance.post('setNewDefects', array)
            .then(response => {
                return response.data
            });
    },
    setBindRepairToCheck(checkId, repairId) {
        return instance.get('setBindRepairToCheck/' + checkId + "/" + repairId)
            .then(response => {
                return response.data
            });
    },
}
export const otherAPI = {
    getProductsList() {
        return instance.get('getProductsList')
            .then(response => {
                return response.data
            });
    },

    getCompanyList() {
        const cacheKey = 'companyListData';
        const cacheExpiryKey = 'companyListExpiry';

        const cachedData = localStorage.getItem(cacheKey);
        const cacheExpiry = localStorage.getItem(cacheExpiryKey);

        if (cachedData && cacheExpiry && new Date().getTime() < cacheExpiry) {
            // Return cached data if it's still valid
            return Promise.resolve(JSON.parse(cachedData));
        }

        return newApiInstance.get('company/list')
            .then(response => {
                const data = response.data?.data;

                localStorage.setItem(cacheKey, JSON.stringify(data));
                localStorage.setItem(cacheExpiryKey, new Date().getTime() + 3600000); // 1 hour from now

                return data;
            });
    },

    getSearchResults(request) {
        return newApiInstance.post('search', request)
            .then(response => {
                return response.data
            });
    },
}

export const salesCheckAPI = {
    getSalesCheck() {
        return newApiInstance.get('invoice/documents-awaits')
            //return instance.get('getSalesCheck')
            .then(response => {
                return response.data
            });
    },
    getSalesCheckArchive() {
        return instance.get('getSalesCheckArchive')
            .then(response => {
                return response.data
            });
    },
    setDateInSalesCheck(id, who) {
        return instance.get('setDateInSalesCheck/' + id + "/" + who)
            .then(response => {
                return response.data
            });
    },
    getSalesInvoceComment() {
        return instance.get('getSalesInvoceComment')
            .then(response => {
                return response.data
            });
    },
    deleteCommentForSalesCheck(id) {
        return instance.get('deleteCommentForSalesCheck/' + id)
            .then(response => {
                return response.data
            });
    },
    addCommentForSalesCheck(array) {
        return instance.post('addCommentForSalesCheck', array)
            .then(response => {
                return response.data
            });
    },
}
export const futureAPI = {
    getAllNomenclaturesData(name) {
        return instance.get('getAllNomenclaturesData/' + name)
            .then(response => {
                return response.data
            });
    },
    setNewNomenclature(array) {
        return instance.post('setNewNomenclature', array)
            .then(response => {
                return response.data
            });
    },
    changeNomenclaturesNames(array) {
        return instance.post('changeNomenclaturesNames', array)
            .then(response => {
                return response.data
            });
    },
    getAllNomenclaturesDataDev(name) {
        return instance.get('getAllNomenclaturesDataDev/' + name)
            .then(response => {
                return response.data
            });
    },
    getPersonalNomenclaturesName() {
        return instance.get('getPersonalNomenclaturesName')
            .then(response => {
                return response.data
            });
    },
    getAllChannelChains() {
        return instance.get('getAllChannelChains')
            .then(response => {
                return response.data
            });
    },
    addNomenclatureParam(type, nameUkr, nameRus) {
        return instance.get('addNomenclatureParam/' + type + '/' + nameUkr + '/' + nameRus)
            .then(response => {
                return response.data
            });
    },
    getAllNom() {
        return instance.get('getAllNom')
            .then(response => {
                return response.data
            });
    },
    getAllApplication() {
        return instance.get('getAllApplication')
            .then(response => {
                return response.data
            });
    },
    getPersonalNomenclaturesAllParams(id) {
        return instance.get('getPersonalNomenclaturesAllParams/' + id)
            .then(response => {
                return response.data
            });
    },
    getApplicationPersonal(id) {
        return instance.get('getApplicationPersonal/' + id)
            .then(response => {
                return response.data
            });
    },
    getPersonalSignal(id) {
        return instance.get('getPersonalSignal/' + id)
            .then(response => {
                return response.data
            });
    },
    getPersonalRange(id, signal) {
        return instance.get('getPersonalRange/' + id + '/' + signal)
            .then(response => {
                return response.data
            });
    },
    getPersonalWire(id, signal, range) {
        return instance.get('getPersonalWire/' + id + '/' + signal + '/' + range)
            .then(response => {
                return response.data
            });
    },
    getAllRange() {
        return instance.get('getAllRange')
            .then(response => {
                return response.data
            });
    },
    getAllSignalType() {
        return instance.get('getAllSignalType')
            .then(response => {
                return response.data
            });
    },
    getAllWire() {
        return instance.get('getAllWire')
            .then(response => {
                return response.data
            });
    },
    setNewChannelCheck(array) {
        return instance.post('setNewChannelCheck', array)
            .then(response => {
                //debugger
                return response.data
            });
    },
}
export const projectAPI = {
    getAllProject() {
        return instance.get('getAllProject')
            .then(response => {
                return response.data
            });
    },
    addNewProject(array) {
        return instance.post('addNewProject', array)
            .then(response => {
                return response.data
            });
    },
    changeProject(array) {
        return instance.post('changeProject', array)
            .then(response => {
                return response.data
            });
    },
    removeProject(id) {
        return instance.get('removeProject/' + id)
            .then(response => {
                return response.data
            });
    },
}
