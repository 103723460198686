import React, {useEffect, useState} from 'react';
import './App.css';
import Header from './components/Header/Header';
import ProductionContainer from './components/Production/ProductionMain/ProductionContainer';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import ChiefContainer from './components/Production/Chief/ChiefContainer';
import EngineerContainer from './components/Production/Engineer/EngineerContainer';
import StorageContainer from './components/Production/Storage/StorageContainer';
import TechEngineerContainer from './components/Production/Engineer/TechEngineer/TechEngineerContainer';
import SuppliersContainer from './components/Production/Suppliers/SuppliersContainer';
import SpecificationsContainer from './components/Production/Specifications/SpecificationsContainer';
import AddNewSpecificationContainer from './components/Production/Specifications/AddNewSpecificationContainer.jsx';
import AdminContainer from './components/Admin/AdminContainer';
import MoneyDeliveryContainer from './components/Admin/MoneyDelivery/MoneyDeliveryContainer';
import SalesContainer from './components/Sales/SalesContainer';
import Statistics from './components/Statistics/Statistics';
import StatisticsModuleContainer from './components/Statistics/StatisticsModules/StatisticsModulesContainer';
import StatisticsBranchContainer from './components/Statistics/StatisticsBranch/StatisticsBranchContainer';
import CashContainer from './components/Cash/CashContainer';
import DDCContainer from './components/Admin/DDC/DDCContainer';
import DDCNewContainer from './components/Admin/DDCNew/DDCNewContainer';
import Menu from './components/Admin/Menu/Menu';
import MainSupplierContainer from './components/Sales/MainSupplier/MainSupplierContainer';
import Specifications from './components/Admin/Specifications/SpecificationsContainer.jsx';
import ShipmentContainer from './components/Admin/Shipment/ShipmentContainer';
import ReceivablesMainContainer from './components/Admin/Receivables/Receivables/ReceivablesMainContainer';
import ShipmentStatisticsContainer from './components/Admin/ShipmentStatistics/ShipmentStatisticsContainer';
import AccountsPayableContainer from './components/Admin/Receivables/AccountsPayable/AccountsPayableContainer';
import PlanContainer from './components/Admin/Plan/PlanContainer';
import SalesContainerAdmin from './components/Admin/Sales/SalesContainer';
import ShipmentContainerChief from './components/Production/Chief/Shipment/ShipmentContainer';
import TaskContainer from './components/Tech/Task/TaskContainer';
import FiltrWantsContainer from './components/Sales/FiltrWants/FiltrWantsContainer';
import KpContainer from './components/Sales/Kp/KpContainer';
import Login from './components/Login/Login';
import TechStorageContainer from './components/Tech/TechStorage/TechStorageContainer';
import TaskContainerAll from './components/Task/TaskContainer';
import StatisticsModelModulesContainer
    from './components/Statistics/StatisticsModelModules/StatisticsModelModulesContainer';
import CheckTestContainer from './components/Sales/CheckTest/CheckTestContainer';
import CheckTestContainerProduction from './components/Production/CheckTest/CheckTestContainer';
import StatisticsMenu from './components/Sales/Statistics/StatisticsMenu';
import CheckValueContainer from './components/Sales/Statistics/CheckValue/CheckValueContainer';
import PrivateOfficeContainer from './components/PersonalArea/PrivateOfficeContainer';
import PersonalAreaSalesContainer from './components/PersonalArea/PersonalAreaSales/PersonalAreaSalesContainer';
import CheckContainer from './components/Production/Check/CheckContainer';
import KpPlanContainer from './components/Sales/Kp/KpPlan/KpPlanContainer';
import ProgrammerContainer from './components/Production/Engineer/Programmer/ProgrammerContainer';
import CostsListContainer from './components/Admin/Costs/List/CostsListContainer';
import RepairContainer from './components/Production/Repair/RepairContainer';
import RepairTechContainer from './components/Production/Repair/RepairTech/RepairTechContainer';
import ApplicationContainer from './components/Sales/Application/ApplicationContainer';
import PersonalModuleContainer from './components/PersonalModule/PersonalModuleContainer';
import SalesPersonalContainer from './components/Sales/SalesPersonal/SalesPersonalContainer';
import DistributionContainer from './components/Sales/Distribution/DistributionContainer';
import SalesCheckContainer from './components/Sales/SalesCheck/SalesCheckContainer';
import ProjectContainer from './components/Sales/Project/ProjectContainer';
import FutureContainer from './components/Tech/Future/FutureContainer';
import FutureContainerDev from './components/Tech/FutureDev/FutureContainer';
import NomenclaturesNameContainer from './components/Tech/FutureDev/NomenclaturesName/NomenclaturesNameContainer';
import AddNewKpContainer from './components/Sales/Kp/AddNewKp/AddNewKpContainer';
import AllCompanyWithManagerContainer from './components/Sales/AllCompanyWithManager/AllCompanyWithManagerContainer';
import AllCompanyOutdatedWithManagerContainer
    from './components/Sales/AllCompanyOutdatedWithManager/AllCompanyOutdatedWithManagerContainer';
import AllSharedCompanyWithManagerContainer
    from './components/Sales/AllSharedCompanyWithManager/AllSharedCompanyWithManagerContainer';
import ChannelChainsContainer from './components/Tech/FutureDev/ChannelChains/ChannelChainsContainer';
import MeetingContainer from './components/Sales/Meeting/MeetingContainer';
import SalesCheckArchiveContainer from './components/Sales/SalesCheck/SalesCheckArchive/SalesCheckArchiveContainer';
import SalesPersonalStatisticsContainer
    from './components/Sales/SalesPersonalStatistics/SalesPersonalStatisticsContainer';
import ManagerSalaryContainer from "./components/Sales/ManagerSalaryMonthly/ManagerSalaryContainer";
import CompanyProfileContainer from "./components/Sales/Company/Profile/CompanyProfileContainer";
import {toast, ToastContainer} from "react-toastify";
import AccessoriesListContainer from './components/Production/Accessories/AccessoriesList/AccessoriesListContainer';
import Cookies from "js-cookie";
import ProfilePage from "./components/Profile/ProfilePage";
import DynamicChartBuilder from "./components/DynamicCharts/DynamicChartBuilder";
import AdminSideMenu from "./components/Admin/AdminSideMenu";
import SalesMapCompanies from "./components/Sales/Company/Map/SalesMapCompanies";
import WarehouseDashboard from "./components/Admin/Dashboards/WarehouseDashboard";
import SalesFunnel from "./components/Admin/Dashboards/SalesFunnel";
import UnhandledCompanies from "./components/Sales/Company/UnhandledCompanies/UnhandledCompaniesContainer";
import AllCompanyWithManagerNew from "./components/Sales/AllCompanyWithManagerNew/AllCompanyWithManager";
import TechConsultationList from "./components/Tech/Techconsultation/TechConsultationList";
import FilterCategoriesCompanies
    from "./components/Sales/Company/FilterCategoriesCompanies/FilterCategoriesCompaniesContainer";
import '@fortawesome/fontawesome-free/css/all.min.css';
import UseCaseAdmin from "./components/Admin/UseCaseAdmin/UseCaseAdmin";
import OperationsTable from "./components/Production/Storage/Delist/OperationsTable";
import AssemblerSallary from "./components/Admin/Salary/Assembler/AssemblerSallary";
import {createTheme} from "@mui/material";
import {ThemeProvider} from "@mui/material/styles";
import VATPage from "./components/Admin/VAT/VATPage";
import {loginAPI} from "./api/api";

function App() {
    //console.log(process.env); // Log all environment variables
    // Check if the user is logged in
    const [currentSessions, setCurrentSessions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);



    const isAdmin = () => {
        return Cookies.get('adminID') !== undefined
            && Cookies.get('adminID') !== null
            && Cookies.get('adminID') !== ''
            && ([1, 34, 44].includes(parseInt(Cookies.get('adminID'))));
    }

    const getCurrentSessionsList = async () => {
        try {
            const currentUserID = Cookies.get('id');
            if (!currentUserID || !Cookies.get('token')) {
                throw new Error('No auth cookies');
            }

            const response = await loginAPI.currentSessionsList(currentUserID);

            if (response?.should_logout === 1) {
                throw new Error('Forced logout');
            }

            if (response?.status === 1) {
                setCurrentSessions(response.sessions);
                setIsAuthenticated(true);
            } else {
                throw new Error(response?.message || 'Unknown error');
            }
        } catch (error) {
            Cookies.remove('token');
            Cookies.remove('id');
            Cookies.remove('adminID');
            setIsAuthenticated(false);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getCurrentSessionsList();
        document.title = 'АКОН - Админпанель';
    }, []);

    if (isLoading) return <div>Завантаження...</div>;

    if (!Cookies.get('token') || !Cookies.get('id') || !isAuthenticated) {
        Cookies.remove('token');
        Cookies.remove('id');
        Cookies.remove('admin_id');

        return (
            <>
                <Login/>
                <ToastContainer/>
            </>
        );
    }

    return (
        <Router>

            {/*process.env.REACT_APP_HOME_URL*/}
            <div className="App">
                <Header
                    isAdmin={isAdmin()}
                />
                {<AdminSideMenu isAdmin={isAdmin()}/>}
                <Routes>
                    <Route path="/login" element={<Login/>}/>
                    {/*IDsecured*/} <Route path="/profile/:userID" element={<ProfilePage/>}/>

                    <Route path="/check" element={<ProductionContainer/>}/>
                    {/* <Route path="/checkPrint/:idCheck/:typeCheck"  element={ <PrintVersionContainer/>}/> */}
                    <Route path="/admin/salary/assemblers" element={<AssemblerSallary/>}/>
                    <Route path="/admin/vat-info" element={<VATPage/>}/>

                    <Route path="/statisticsModuls" element={<StatisticsMenu/>}/>
                    <Route path="/statisticsModuls/averageValue" element={<CheckValueContainer/>}/>
                    {/* <Route path="/production" element={ <ProductionMainContainer/>}/> */}
                    <Route path="/check-t-production" element={<CheckTestContainerProduction/>}/>
                    <Route path="/check/:idCheck/:typeCheck" element={<CheckContainer/>}/>
                    <Route path="/salesCheck/" element={<SalesCheckContainer/>}/>
                    <Route path="/salesCheckArchive" element={<SalesCheckArchiveContainer/>}/>
                    <Route path="/chief" element={<ChiefContainer/>}/>
                    <Route path="/task/:personalId" element={<TaskContainerAll/>}/>
                    <Route path="/chief/statistics/:year/:month" element={<ShipmentContainerChief/>}/>
                    <Route path="/engineer" element={<EngineerContainer/>}/>
                    <Route path="/engineer/:workerId" element={<EngineerContainer/>}/>
                    <Route path="/techEngineer" element={<TechEngineerContainer/>}/>
                    <Route path="/techconsultation" element={<TechConsultationList/>}/>

                    <Route path="/tech/tasks" element={<TaskContainer/>}/>
                    <Route path="/tech/techStorage" element={<TechStorageContainer/>}/>
                    <Route path="/tech/future" element={<FutureContainer/>}/>
                    <Route path="/tech/nomenclaturesName" element={<NomenclaturesNameContainer/>}/>
                    <Route path="/tech/futuredev" element={<FutureContainerDev/>}/>
                    <Route path="/tech/futuredev/channelchains" element={<ChannelChainsContainer/>}/>
                    {/* <Route path="/tech"  element={ <Tech/>}/> */}

                    <Route path="/storage/:section" element={<StorageContainer/>}/>
                    <Route path="/delist/story" element={<OperationsTable/>}/>
                    <Route path="/suppliers" element={<SuppliersContainer/>}/>
                    <Route path="/suppliers/:suppliers" element={<SuppliersContainer/>}/>
                    {/* <Route path="/modulsWithComponent/:modulsWithComponent?" */}
                    {/* element={ <ModulesWithComponentContainer />}/> */}
                    {/* <Route path="/order" element={ <OrderComponent/>}/> */}
                    <Route path="/add_specifications" element={<AddNewSpecificationContainer/>}/>
                    <Route path="/specifications" element={<SpecificationsContainer/>}/>
                    <Route path="/specifications/:productId" element={<SpecificationsContainer/>}/>

                    <Route path="/admin/use-cases" element={<><UseCaseAdmin/></>}/>
                    <Route path="/admin" element={<><Menu/><AdminContainer/></>}/>
                    <Route path="/admin/ddc" element={<><Menu/><DDCContainer/></>}/>
                    <Route path="/admin/ddc/:year" element={<><Menu/><DDCContainer/></>}/>
                    <Route path="/admin/ddc/:year/:month" element={<><Menu/><DDCContainer/></>}/>
                    <Route path="/admin/ddcNew" element={<><Menu/><DDCNewContainer/></>}/>
                    <Route path="/admin/ddcNew/:year" element={<><Menu/><DDCNewContainer/></>}/>
                    <Route path="/admin/costslist/:year/:month" element={<><Menu/><CostsListContainer/></>}/>
                    <Route path="/admin/moneydel" element={<><Menu/><MoneyDeliveryContainer/></>}/>
                    <Route path="/admin/specification" element={<><Menu/><Specifications/></>}/>
                    <Route path="/admin/specification/:productId" element={<><Menu/><Specifications/></>}/>
                    <Route path="/admin/shipment" element={<><Menu/><ShipmentContainer/></>}/>
                    <Route path="/admin/shipment/:month" element={<><Menu/><ShipmentContainer/></>}/>
                    <Route path="/admin/receivables" element={<><Menu/><ReceivablesMainContainer/></>}/>
                    <Route path="/admin/accountsPayable" element={<><Menu/><AccountsPayableContainer/></>}/>
                    <Route path="/admin/shipmentStatistics/:year"
                           element={<><Menu/><ShipmentStatisticsContainer/></>}/>
                    <Route path="/admin/plan2022" element={<><Menu/><PlanContainer/></>}/>
                    {/*IDsecured*/} <Route path="/admin/sales/:personalId"
                                           element={<><Menu/><SalesContainerAdmin/> </>}/>

                    <Route path="/wants/:personalId" element={<FiltrWantsContainer/>}/>
                    {/* <Route path="/maps"  element={ <MapsContainer/>}/> */}
                    {/* <Route path="/company"  element={ <CompanyContainer/>}/> */}
                    {/*<Route path="/sales"  element={ () => <SalesContainer /> } />*/}
                    <Route path="/sales" element={<FilterCategoriesCompanies/>}/>
                    <Route path="/kp/:idManager" element={<KpContainer/>}/>

                    <Route path="/kp/add/:idManager"
                           element={<AddNewKpContainer/>}/> {/* Add new / edit KP */}
                    <Route path="/kp/add/:idManager/:idKp" element={<AddNewKpContainer/>}/>
                    <Route path="/kp/add/:idManager/:idKp/:isInvoice" element={<AddNewKpContainer/>}/>

                    <Route path="/kpPlan/:idManager" element={<KpPlanContainer/>}/>

                    {/*<Route path="/sales/:arrayMainSuppliers?"  element={ <SalesContainer/>}/>*/}
                    <Route path="/sales/personalStatistics" element={<SalesPersonalStatisticsContainer/>}/>
                    <Route path="/sales/personalStatistics/:personalId" element={<SalesPersonalStatisticsContainer/>}/>
                    <Route path="/sales/mainSupplier" element={<MainSupplierContainer/>}/>
                    <Route path="/sales/allCompany/:manager" element={<AllCompanyWithManagerContainer/>}/>
                    <Route path="/sales/allCompanyNew/:manager" element={<AllCompanyWithManagerNew/>}/>
                    <Route path="/sales/allCompanyOutdated/:manager"
                           element={<AllCompanyOutdatedWithManagerContainer/>}/>
                    <Route path="/sales/allSharedCompany/:manager"
                           element={<AllSharedCompanyWithManagerContainer/>}/>
                    <Route path="/sales/meeting/:idManager" element={<MeetingContainer/>}/>
                    <Route path="/sales/salary/:idManager" element={<ManagerSalaryContainer/>}/>
                    <Route path="/sales/salary/:idManager/:year" element={<ManagerSalaryContainer/>}/>
                    <Route path="/sales/salary/:idManager/:year/:month" element={<ManagerSalaryContainer/>}/>
                    <Route path="/company/profile/:companyID" element={<CompanyProfileContainer/>}/>
                    <Route path="/company/unhandled" element={<UnhandledCompanies/>}/>
                    {/*<Route path="/company/filter-category"  element={ <FilterCategoriesCompanies/>}/>*/}
                    <Route path="/salesPersonal/:personalId" element={<SalesPersonalContainer/>}/>

                    <Route path="/check-t" element={<CheckTestContainer/>}/>

                    <Route path="/statistics" element={<Statistics/>}/>
                    <Route path="/statisticsModules" element={<StatisticsModuleContainer/>}/>
                    <Route path="/statisticsModelModules" element={<StatisticsModelModulesContainer/>}/>
                    <Route path="/statisticsBranch" element={<StatisticsBranchContainer/>}/>
                    <Route path="/cash" element={<><Menu/><CashContainer/></>}/>
                    <Route path="/cash/:month" element={<><Menu/><CashContainer/></>}/>
                    <Route path="/personalAreaSalesCheck/:personalId" element={<PersonalAreaSalesContainer/>}/>

                    <Route path="/privateOffice/:personalId" element={<PrivateOfficeContainer/>}/>

                    <Route path="/progProduction" element={<ProgrammerContainer/>}/>

                    <Route path="/repair" element={<RepairContainer/>}/>
                    <Route path="/repair/tech" element={<RepairTechContainer/>}/>
                    <Route path="/application" element={<ApplicationContainer/>}/>
                    <Route path="/personalModule/:nom" element={<PersonalModuleContainer/>}/>
                    <Route path="/distribution/:personalId" element={<DistributionContainer/>}/>
                    <Route path="/project" element={<ProjectContainer/>}/>

                    <Route path="/accessories/accessoriesList" element={<AccessoriesListContainer/>}/>

                    {/* Dashboards */}
                    <Route path="/dashboards/new" element={<DynamicChartBuilder/>}/>
                    <Route path="/dashboards/warehouse" element={<WarehouseDashboard/>}/>
                    <Route path="/dashboards/sales-funnel" element={<SalesFunnel/>}/>
                    <Route path="/company/map" element={<SalesMapCompanies/>}/>
                </Routes>
                <ToastContainer/>
            </div>


        </Router>
    );
}

export default App;
