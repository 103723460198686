import React from 'react';
import { Button, ButtonGroup } from '@mui/material';

const currentYear = new Date().getFullYear();
const YEARS = [currentYear, currentYear - 1, currentYear - 2];

function YearSelector({ selectedYear, onSelect }) {
    return (
        <ButtonGroup variant="outlined" color="primary" aria-label="year selector">
            {YEARS.map((year) => (
                <Button
                    key={year}
                    variant={selectedYear === year ? 'contained' : 'outlined'}
                    onClick={() => onSelect(year)}
                >
                    {year}
                </Button>
            ))}
        </ButtonGroup>
    );
}

export default YearSelector;