    import React from 'react';
    import { compose } from 'redux';
    import { connect } from 'react-redux';
    import {
        getSumAndNameSubetriesForMonth,
        getSumPaidCostsForMonth,
        getAllPaidCostsForMonthFromSubetries,
        getSumAndNameArticlesForMonth
    } from '../../../redux/admin-reducer';
    import Costs from './Costs';

    class CostsContainer extends React.Component {
        componentDidMount() {
            this.props.getSumAndNameArticlesForMonth(this.props.month, this.props.year);
            this.props.getSumPaidCostsForMonth(this.props.month, this.props.year);
        }

        componentDidUpdate(prevProps) {
            if (prevProps.month !== this.props.month || prevProps.year !== this.props.year) {
                this.props.getSumAndNameArticlesForMonth(this.props.month, this.props.year);
                this.props.getSumPaidCostsForMonth(this.props.month, this.props.year);
            }
        }

        render() {
            return (
                <div>
                    {console.log('this.props.sumCosts', this.props.sumCosts)}
                    <Costs
                        checkCosts={this.props.checkCosts}
                        sumCosts={this.props.sumCosts}
                        subentries={this.props.subentries}
                        getAllPaidCostsForMonthFromSubetries={this.props.getAllPaidCostsForMonthFromSubetries}
                        month={this.props.month}
                        articles={this.props.articles}
                        getSumAndNameSubetriesForMonth={this.props.getSumAndNameSubetriesForMonth}
                        year={this.props.year}
                    />
                </div>
            );
        }
    }

    const mapStateToProps = (state) => ({
        checkCosts: state.admin.checkCosts,
        sumCosts: state.admin.sumCosts,
        subentries: state.admin.subentries,
        articles: state.admin.articles
    });

    export default compose(
        connect(mapStateToProps, {
            getSumAndNameSubetriesForMonth,
            getSumPaidCostsForMonth,
            getAllPaidCostsForMonthFromSubetries,
            getSumAndNameArticlesForMonth
        })
    )(CostsContainer);
