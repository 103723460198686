import React, {useEffect, useState} from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Typography,
    TextField,
    Button,
    Chip,
    FormControlLabel,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    ListItemText
} from '@mui/material';
import {NavLink} from 'react-router-dom';
import {companyAPI} from '../../../../api/api';
import Cookies from 'js-cookie';
import {salesManagersList} from '../../../global/ManagersList';
import axios from "axios";

const FilterCategoriesCompanies = () => {
    const [companies, setCompanies] = useState({});
    const [categories, setCategories] = useState({});
    const [industries, setIndustries] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedIndustries, setSelectedIndustries] = useState([]);
    const [categoryDialogOpen, setCategoryDialogOpen] = useState(false);
    const [industryDialogOpen, setIndustryDialogOpen] = useState(false);
    const [filterMode, setFilterMode] = useState(false);
    const [showEmails, setShowEmails] = useState(false);
    const [emailOnly, setEmailOnly] = useState(false);
    const [selectedManagerID, setSelectedManagerID] = useState(null);
    const [tagsList, setTagsList] = useState([]); // список всіх тегів
    const [selectedTags, setSelectedTags] = useState([]); // обрані теги
    const [tagsLoaded, setTagsLoaded] = useState(false);

    const isAdmin = !!Cookies.get('adminID');
    const currentUserID = Cookies.get('userid');

    const loadTagsList = async () => {
        const companiesIDsList = Object.keys(companies);
        if (companiesIDsList.length === 0) return;

        try {
            const response = await companyAPI.getRelatedCompaniesTags(companiesIDsList);
            if (response.data?.status === 1) {
                const tagsArray = Object.entries(response.data.data).map(([id, name]) => ({
                    id,
                    name,
                }));
                setTagsList(tagsArray);
                setTagsLoaded(true); // ⬅️ додано
            }
        } catch (e) {
            console.error('Помилка при завантаженні тегів', e);
        }
    };


    const loadCompanies = async () => {
        const watchAsUserID = currentUserID;
        const watchUserID = selectedManagerID || currentUserID;

        await companyAPI.getCompanyListWithCategoriesAndIndustries({
            watchAsUserID,
            watchUserID,
        }).then((response) => {
            setCompanies(response.data);
            setCategories(response.categories);
            setIndustries(response.industries);
        });
    };

    useEffect(() => {
        loadTagsList();
    }, [companies]);

    useEffect(() => {
        loadCompanies();
    }, [selectedManagerID]);

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleCategoryChipDelete = (chipToDelete) => () => {
        setSelectedCategories((chips) => chips.filter((chip) => chip !== chipToDelete));
    };

    const handleIndustryChipDelete = (chipToDelete) => () => {
        setSelectedIndustries((chips) => chips.filter((chip) => chip !== chipToDelete));
    };

    const handleCategoryDialogOpen = () => setCategoryDialogOpen(true);
    const handleCategoryDialogClose = () => setCategoryDialogOpen(false);
    const handleIndustryDialogOpen = () => setIndustryDialogOpen(true);
    const handleIndustryDialogClose = () => setIndustryDialogOpen(false);

    const handleFilterModeChange = (event) => setFilterMode(event.target.checked);
    const handleShowEmailsChange = () => {
        if (showEmails) setEmailOnly(false);
        setShowEmails((prev) => !prev);
    };

    const handleEmailOnlyChange = () => setEmailOnly((prev) => !prev);

    const handleManagerChange = (id) => setSelectedManagerID(id === 'all' ? null : id);

    const rows = [];
    const filteredCompanies = Object?.keys(companies)?.filter((key) => {
        const company = companies[key];
        const matchesSearchQuery = company.name_with_akon.toLowerCase().includes(searchQuery.toLowerCase());

        const companyCategoryIDs = company.categories ? Object.keys(company.categories) : [];
        const companyIndustryIDs = company.industries ? Object.keys(company.industries) : [];

        let matchesCategories = true;
        let matchesIndustries = true;

        if (selectedCategories.length > 0) {
            if (filterMode) {
                matchesCategories = selectedCategories.every((id) => companyCategoryIDs.includes(id));
            } else {
                matchesCategories = selectedCategories.some((id) => companyCategoryIDs.includes(id));
            }
        }

        if (selectedIndustries.length > 0) {
            if (filterMode) {
                matchesIndustries = selectedIndustries.every((id) => companyIndustryIDs.includes(id));
            } else {
                matchesIndustries = selectedIndustries.some((id) => companyIndustryIDs.includes(id));
            }
        }

        let matchesTags = true;

        if (selectedTags.length > 0) {
            const companyTags = (company.p_company_segmentation_wants || '')
                .split(',')
                .map(id => id.trim())
                .filter(id => !!id);

            if (filterMode) {
                matchesTags = selectedTags.every((tagId) => companyTags.includes(tagId));
            } else {
                matchesTags = selectedTags.some((tagId) => companyTags.includes(tagId));
            }
        }

        return matchesSearchQuery && matchesCategories && matchesIndustries && matchesTags;
    });


    filteredCompanies?.forEach((key) => {
        const company = companies[key];

        // Формуємо список тегів
        const companyTags = (company.p_company_segmentation_wants || '')
            .split(',')
            .map((id) => id.trim())
            .filter((id) => id);

        const tagsString = companyTags
            .map((id) => {
                const tag = tagsList.find(t => t.id.toString() === id);
                return tag?.name || null;
            })
            .filter(Boolean)
            .join(', ');

        // Базова інфа по компанії
        const companyInfo = {
            id: key,
            name_with_akon: company.name_with_akon,
            categories: Object.values(company.categories || {}).join(', '),
            industries: Object.values(company.industries || {}).join(', '),
            tags: tagsString,
        };

        // Обробка email-режиму
        if (showEmails && company.contacts && Object.keys(company.contacts).length > 0) {
            Object.values(company.contacts).forEach((contact) => {
                rows.push({
                    ...companyInfo,
                    email: contact.email,
                });
            });
        } else if (!emailOnly) {
            rows.push({
                ...companyInfo,
                email: '',
            });
        }
    });

    return (
        <Box p={2}>
            <Typography variant="h6">Список компаний (всего: {Object.keys(companies).length})</Typography>

            {isAdmin && (
                <Box display="flex" alignItems="center" mt={2} flexWrap="wrap">
                    <Button
                        variant={selectedManagerID === null ? 'contained' : 'outlined'}
                        onClick={() => handleManagerChange('all')}
                        style={{marginRight: '8px'}}
                    >
                        Все
                    </Button>
                    {salesManagersList.map((manager) => (
                        <Button
                            key={manager.id}
                            variant={selectedManagerID === manager.id ? 'contained' : 'outlined'}
                            onClick={() => handleManagerChange(manager.id)}
                            style={{marginRight: '8px', marginBottom: '8px'}}
                        >
                            {manager.name}
                        </Button>
                    ))}
                </Box>
            )}

            <Box display="flex" alignItems="center" flexWrap="wrap" mt={2}>
                <TextField
                    label="Поиск по названию компании"
                    variant="outlined"
                    value={searchQuery}
                    onChange={handleSearch}
                    size="small"
                    style={{marginRight: '16px', flexGrow: 1}}
                />
                <FormControlLabel
                    control={<Checkbox checked={filterMode} onChange={handleFilterModeChange}/>}
                    label="Жёсткий фильтр"
                />
                <Button variant="contained" onClick={handleShowEmailsChange} style={{marginLeft: '16px'}}>
                    {showEmails ? 'Скрыть Email' : 'Показать Email'}
                </Button>
                <Button
                    variant="contained"
                    onClick={handleEmailOnlyChange}
                    style={{marginLeft: '16px', display: showEmails ? 'block' : 'none'}}
                >
                    {emailOnly ? 'Показать все поля' : 'Показать только Email'}
                </Button>
            </Box>

            <Box mt={2} display="flex" alignItems="center" flexWrap="wrap">
                <Typography variant="body1" style={{marginRight: '8px'}}>Категории:</Typography>
                {selectedCategories.map((id) => (
                    <Chip
                        key={id}
                        label={categories[id]}
                        onDelete={handleCategoryChipDelete(id)}
                        style={{margin: '4px'}}
                    />
                ))}
                <Chip
                    label="Добавить категории"
                    style={{backgroundColor: '#4caf50', color: 'white', margin: '4px'}}
                    onClick={handleCategoryDialogOpen}
                />
            </Box>

            <Box mt={2} display="flex" alignItems="center" flexWrap="wrap">
                <Typography variant="body1" style={{marginRight: '8px'}}>Отрасли:</Typography>
                {selectedIndustries.map((id) => (
                    <Chip
                        key={id}
                        label={industries[id]}
                        onDelete={handleIndustryChipDelete(id)}
                        style={{margin: '4px'}}
                    />
                ))}
                <Chip
                    onClick={handleIndustryDialogOpen}
                    label="Добавить отрасли"
                    style={{backgroundColor: '#4caf50', color: 'white', margin: '4px'}}
                />
            </Box>

            {/*box for selecting using tags - like at wants page*/}
            <Box mt={2} display="flex" alignItems="center" flexWrap="wrap">
                <Typography variant="body1" style={{marginRight: '8px'}}>Теги:</Typography>
                {selectedTags.map((tagID) => {
                    const tag = tagsList.find(t => t.id.toString() === tagID);

                    return (
                        <Chip
                            key={tagID}
                            label={tag?.name || tagID}
                            onDelete={() => setSelectedTags((prev) => prev.filter((id) => id !== tagID))}
                            style={{margin: '4px'}}
                        />
                    );
                })}
                {tagsList
                    .filter((tag) => !selectedTags.includes(tag.id.toString()))
                    .map((tag) => (
                        tag !=='' &&
                        <Chip
                            key={tag.id}
                            label={tag.name}
                            onClick={() => setSelectedTags((prev) => [...prev, tag.id.toString()])}
                            style={{margin: '4px', backgroundColor: '#e0f7fa'}}
                        />
                    ))}
            </Box>


            <Dialog open={categoryDialogOpen} onClose={handleCategoryDialogClose} maxWidth="sm" fullWidth>
                <DialogTitle>Выберите категории</DialogTitle>
                <DialogContent>
                    <FormControl variant="outlined" fullWidth margin="normal">
                        <InputLabel>Категории</InputLabel>
                        <Select
                            multiple
                            value={selectedCategories}
                            onChange={(e) => setSelectedCategories(e.target.value)}
                            renderValue={(selected) => selected.map(id => categories[id]).join(', ')}
                            label="Категории"
                        >
                            {Object.keys(categories).map((id) => (
                                <MenuItem key={id} value={id}>
                                    <Checkbox checked={selectedCategories.indexOf(id) > -1}/>
                                    <ListItemText primary={categories[id]}/>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCategoryDialogClose} color="primary">
                        Закрыть
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={industryDialogOpen} onClose={handleIndustryDialogClose} maxWidth="sm" fullWidth>
                <DialogTitle>Выберите отрасли</DialogTitle>
                <DialogContent>
                    <FormControl variant="outlined" fullWidth margin="normal">
                        <InputLabel>Отрасли</InputLabel>
                        <Select
                            multiple
                            value={selectedIndustries}
                            onChange={(e) => setSelectedIndustries(e.target.value)}
                            renderValue={(selected) => selected.map(id => industries[id]).join(', ')}
                            label="Отрасли"
                        >
                            {Object.keys(industries).map((id) => (
                                <MenuItem key={id} value={id}>
                                    <Checkbox checked={selectedIndustries.indexOf(id) > -1}/>
                                    <ListItemText primary={industries[id]}/>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleIndustryDialogClose} color="primary">
                        Закрыть
                    </Button>
                </DialogActions>
            </Dialog>

            {!tagsLoaded ? (
                <Typography mt={3}>Завантаження тегів...</Typography>
            ) : (
                <TableContainer component={Paper} style={{marginTop: '16px'}}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                {!emailOnly && <TableCell>Название компании</TableCell>}
                                {!emailOnly && <TableCell>Категории</TableCell>}
                                {!emailOnly && <TableCell>Отрасли</TableCell>}
                                {!emailOnly && <TableCell>Теги</TableCell>}
                                {showEmails && <TableCell>Email</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, index) => (
                                <TableRow key={index}>
                                    {!emailOnly && (
                                        <TableCell>
                                            <NavLink to={`/company/profile/${row.id}`}>
                                                {row.name_with_akon}
                                            </NavLink>
                                        </TableCell>
                                    )}
                                    {!emailOnly && <TableCell>{row.categories}</TableCell>}
                                    {!emailOnly && <TableCell>{row.industries}</TableCell>}
                                    {!emailOnly && (
                                        <TableCell>
                                            {row.tags && row.tags.trim() !== '' && row.tags
                                                .split(', ')
                                                .map((name, idx) => (
                                                    <Chip key={idx} label={name} size="small" style={{ margin: 2 }} />
                                                ))}


                                        </TableCell>
                                    )}
                                    {showEmails && <TableCell>{row.email}</TableCell>}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

        </Box>
    );
};

export default FilterCategoriesCompanies;
