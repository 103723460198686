import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import MonthBlock from '../../Production/common/MonthBlock/MonthBlock';
import CostsContainer from '../Costs/CostsContainer';
import IncomeContainer from '../Income/IncomeContainer';
import BalanceContainer from './Balance/BalanceContainer';
import s from './DDC.module.css';
import NewScheduleContainer from './newSchedule/NewScheduleContainer';
import Button from "@mui/material/Button";
import {FileDownload} from "@mui/icons-material";

const DDC = (props) => {
    const { month: monthParam, year: yearParam } = useParams(); // Access route parameters using useParams
    const sum = props.sum.map(k => k.sum);
    const sumRu = props.sumRu.map(k => k.sum);
    const sumCosts = props.sumCosts.map(k => k.sum);

    /*console.log(
        //props.components,
    props.sumCosts,
    props.sum,
    props.sumRu,
    props.sumMoneyDelivery
    )*/

    // Fallback to current month and year if no params are provided
    let month, year;
    if (monthParam) {
        month = monthParam;
    } else {
        let date = new Date();
        month = date.getMonth() + 1;
    }
    if (yearParam) {
        year = yearParam;
    } else {
        let date = new Date();
        year = date.getFullYear();
    }

    const num = Number(month) - 1;
    const result = sum[num] - sumCosts[num] + sumRu[num];
    const resultMain = result.toLocaleString();

    return (
        <div>
            <div className={s.main}>
                {
                    Array.from({ length: new Date().getFullYear() - 2021 }, (v, k) => k + 2022).map((item) => {
                        return <div className={year == item.toString() ? s.active : ""}><NavLink to={`/admin/ddc/${item}/12`}>{item}</NavLink></div>
                    })
                }
            </div>

            <MonthBlock path={`admin/ddc/${year}`} month={month} />
            <Button variant="contained" target="_blank" color="primary" href={`https://api.akon.com.ua/api/ddc/excel/${year}`} sx={{margin: '10px 0'}}>
                <FileDownload />
                Скачать Excel
            </Button>

            <IncomeContainer month={month} year={year} />
            <CostsContainer month={month} year={year} />
            <h2><span className={s.title}>Результат: {resultMain} грн</span></h2>
            <BalanceContainer month={month} result={result} year={year} />

            <NewScheduleContainer />
        </div>
    );
}

export default DDC;
