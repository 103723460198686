import React, {useState, useEffect} from 'react';
import s from './AddNewKp.module.css';
import main from '../../../Main.module.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash, faPlus} from '@fortawesome/free-solid-svg-icons';
import {Link, NavLink} from 'react-router-dom';
import PrintKp from './PrintKp';
import AddAgents from '../../../Production/Check/Messages/Modal/AddAgents';
import Modal2 from '../../../Production/common/Modal2/Modal';
import Modal from '../../../Production/common/Modal/Modal';
import {toast} from "react-toastify";
import {
    Autocomplete,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button, Switch, FormGroup, FormControlLabel
} from '@mui/material';
import CircularProgress from "@mui/material/CircularProgress";
import {salesAPI, specificationAPI} from "../../../../api/api";
import {Stack} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import Input from "@mui/material/Input";

const AddNewKp = (props) => {


    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [productType, setProductType] = useState('');
    const [internalProduct, setInternalProduct] = useState(false);

    let [companyId, setCompanyId] = useState(props?.kpInfoByID?.[0]?.company_id ?? '');
    let [companyName, setCompanyName] = useState("");
    let [contact, setContact] = useState(props.kpInfoByID?.[0]?.email ?? "");
    let [statusKp, setStatusKp] = useState(props?.kpInfoByID?.[0]?.statusKp ?? "");
    let [newKp, setNewKp] = useState([]);
    let [euro, setEuro] = useState(props?.kpInfoByID?.[0]?.exchange_rate_eur ?? props?.course?.[1]?.numeral ?? 0);
    let [doll, setDoll] = useState(props?.kpInfoByID?.[0]?.exchange_rates ?? props?.course?.[0]?.numeral ?? 0);
    let [discount, setDiscount] = useState(0);
    let [productionTime, setProductionTime] = useState(null);
    let [showPrint, setShowPrint] = useState(false);
    let [addAgent, setAddAgent] = useState(false);
    let [showKPlink, setShowKPLink] = useState(false);
    let [kpSaved, setKpSaved] = useState(0);
    let [hideButtons, setHideButtons] = useState(false);
    let [deleteKPWindow, setDeleteKPWindow] = useState(false);

    window.addEventListener("beforeunload", (ev) => {
        if (!showKPlink) {
            ev.preventDefault();
            return ev.returnValue = 'Точно хотите закрыть окно? КП Будет удалено.';
        }
    });

    async function setCompanyIdF(event) {
        const selectedCompanyName = event.target.value;
        setCompanyName(selectedCompanyName);
        setContact("");

        const selectedOption = props?.companyList?.find(k => k.name_with_akon === selectedCompanyName);
        if (selectedOption) {
            const selectedCompanyId = selectedOption.id;
            setCompanyId(selectedCompanyId);

            // Завантаження агентів компанії
            await props.getAgentsPersonalCompany(selectedCompanyId);

            // Встановлення збереженого контакту, якщо він існує
            const savedContact = props?.kpInfoByID?.[0]?.email;
            if (savedContact) {
                const matchingContact = props.companyAgents.find(agent => agent.email === savedContact);
                setContact(matchingContact ? savedContact : '');
            }
        } else {
            setCompanyId('');
            setContact('');
        }
    }

    useEffect(() => {
        document.title = 'АКОН - Создание КП/счёта';
        fetchOptions();
    }, []);

    const fetchOptions = async () => {
        setIsLoading(true);
        try {
            const response = await salesAPI.getAllModulesNomenclatures().then((response) => {
                let formattedResponse = response.data;
                setOptions(formattedResponse);
            });
        } catch (error) {
            console.error("Error fetching options:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (props.companyList && props.kpInfoByID?.length > 0) {
            const selectedCompany = props?.companyList?.find(k => k.id === props.kpInfoByID[0].company_id);
            if (selectedCompany) {
                setCompanyId(selectedCompany.id);
                setCompanyName(selectedCompany.name_with_akon);
            }
        }
    }, [props.companyList, props.kpInfoByID]);
    useEffect(() => {
        // Шукаємо збережений контакт в kpInfoByID
        const savedContact = props?.kpInfoByID?.[0]?.email;
        if (props.companyAgents?.length > 0 && savedContact) {
            const existingContact = props.companyAgents.find(agent => agent.email === savedContact);
            if (existingContact) {
                setContact(savedContact);
            } else {
                setContact(''); // Якщо немає збереженого контакту в списку
            }
        }
    }, [props.companyAgents, props?.kpInfoByID]);

    const setContactF = (e) => {
        setContact(e.target.value);
    }
    const setStatusKpF = (e) => {
        setStatusKp(e.target.value)
    }
    const moneyToHRN = (value, currency, ratioFromHRN) => {
        return value * 1.2 / ratioFromHRN
    }
    const loadPosition = () => {
        if (props?.kpInfoByID?.length > 0) {
            setDiscount(props?.kpInfoByID?.[0]?.discount ?? 0)
            setStatusKp(props?.kpInfoByID?.[0]?.status_by_importance ?? 0)
            //setProductionTime(props?.kpInfoByID?.[0]?.productionTime ?? 1)
            setCompanyId(props?.kpInfoByID?.[0]?.company_id);
            setCompanyName(props?.companyList?.find(k => k.id == props?.kpInfoByID?.[0]?.company_id)?.name_with_akon)
            setCompanyIdF({target: {value: props?.companyList?.find(k => k.id == props?.kpInfoByID?.[0]?.company_id)?.name_with_akon}})
            setContact(props?.kpInfoByID?.[0]?.email ?? "")
            setShowKPLink(true)
            //setDoll(props?.kpInfoByID?.[0]?.exchange_rates ?? props?.course?.[0]?.numeral ?? 0)
            //setEuro(props?.kpInfoByID?.[0]?.exchange_rate_eur ?? props?.course?.[1]?.numeral ?? 0)
            const updateKp = props.kpInfoByID?.map(k => {
                let originalData = JSON.parse(k?.original_data_json ?? '{}')

                return {
                    "name": k.moduls_name,
                    "desc": k.moduls_desc ?? '',
                    "coll": k.moduls_coll,
                    "currencyType": parseInt(originalData.currencyType) ?? 2,
                    "price": originalData.price,
                    "discountType": originalData.discountType ?? 2,
                    "nomenclatureAkon": null,
                    "internal_product": originalData?.internal_product ?? false
                }
            })
            console.log(updateKp)
            setNewKp(updateKp)
        }
    }
    useEffect(() => {
        if (props?.kpInfoByID?.length > 0) {
            loadPosition()
        }
    }, [props.kpInfoByID]);

    const openDialog = () => {
        setDialogOpen(true);
    };

    const closeDialog = () => {
        setDialogOpen(false);
    };

    const handleProductTypeChange = (event) => {
        const selectedType = event.target.value;
        setProductType(selectedType);
        setInternalProduct(selectedType === 'internal');
        closeDialog();
        addNewPosition(selectedType === 'internal');
    };

    const addNewPosition = (isInternal) => {
        const updateKp = [...newKp, {
            "name": "",
            "desc": "",
            "coll": 1,
            "currencyType": 1,
            "price": "0",
            "discountType": 1,
            "nomenclatureAkon": null,
            "internal_product": isInternal
        }]
        setNewKp(updateKp);
    };

    const setNewValueF = (id, e, field) => {
        const updatedKp = [...newKp];
        updatedKp[id][field] = e.target.value;

        if (field === "price") {
            updatedKp[id].originalPrice = parseFloat(e.target.value); // Обновляем originalPrice, если пользователь изменил цену
        }

        setNewKp(updatedKp); // Устанавливаем новое состояние
    };


    const setNewDataF = (id, e, field) => {
        const updateKp = [...newKp];
        updateKp[id][field] = e;
        setNewKp(updateKp);
    }

    const deleteObjectF = (index) => {
        const updateKp = [...newKp];
        updateKp.splice(index, 1);
        setNewKp(updateKp);
    }

    const collSum = newKp.reduce((accumulator, currentValue) => {
        return Number(accumulator) + Number(currentValue.coll);
    }, 0);

    useEffect(() => {
        if (props?.course?.length > 0) {
            doll > 0 ?? setDoll(props?.course[0].numeral);
            euro > 0 ?? setEuro(props?.course[1].numeral);
        }
    }, [props.course]);

    const handleCurrencyChange = (event) => {
        const value = event.target.value;
        if (event.target.name === "euro") {
            setEuro(value);
        } else if (event.target.name === "doll") {
            setDoll(value);
        }
    }

    const handleProductionTimeChange = (event) => {
        setProductionTime(event.target.value);
    }

    const handleDiscountChange = (event) => {
        setDiscount(event.target.value);
    }

    const getPriceWithDiscount = (price, discountType) => {
        if (discount > 0 && discountType === 1) {
            // Применяем скидку только если discountType равен 1
            const discountValue = (price * discount) / 100;
            return price - discountValue; // Цена после применения скидки
        }
        return price; // Без скидки
    };


    const notNds = (currencyType, price, discountType) => {
        price = getPriceWithDiscount(price, discountType);
        if (currencyType === 1) {
            return Math.ceil((price * doll) / 1.2); // Доллары в грн, без НДС, округляем вверх
        } else if (currencyType === 3) {
            return Math.ceil((price * euro) / 1.2); // Евро в грн, без НДС, округляем вверх
        } else if (currencyType === 2) {
            return Math.ceil(price); // Гривны округляем вверх
        }
        return 0;
    };


    const withNds = (currencyType, price, discountType) => {
        let number = 0;
        price = getPriceWithDiscount(price, discountType)
        if (currencyType === 1) {
            number = (price * doll).toFixed(2)
        } else if (currencyType === 2) {
            number = (price * 1.2).toFixed(2)
        } else if (currencyType === 3) {
            number = (price * euro).toFixed(2)
        }
        return number;
    }

    const withCollAndNds = (currencyType, price, coll, discountType) => {
        let number = 0;
        price = getPriceWithDiscount(price, discountType)
        if (currencyType === 1) {
            number = (price * doll * coll).toFixed(2)
        } else if (currencyType === 2) {
            number = (price * 1.2 * coll).toFixed(2)
        } else if (currencyType === 3) {
            number = (price * euro * coll).toFixed(2)
        }
        return number;
    }

    const recalculation = (array, isInvoice) => {
        console.log('isInvoice: ' + isInvoice)
        return array.map((k) => {
            let originalPrice = parseFloat(k.originalPrice ?? k.price); // Если originalPrice не задано, используем текущую цену
            let coll = parseFloat(k.coll); // Количество
            let discountedPrice = getPriceWithDiscount(originalPrice, k.discountType); // Применяем скидку
            let newPrice = discountedPrice;

            // Пересчет "грн без НДС"
            let notNdsValue = 0;
            let roundedNotNds = 0;

            if (k.currencyType === 1) {
                // Доллары
                notNdsValue = (discountedPrice * doll) / 1.2;
                if (!isInvoice) {
                    notNdsValue = Math.ceil((discountedPrice * doll) / 1.2); // Конвертируем, вычитаем НДС и округляем вверх
                }
                newPrice = (parseFloat(notNdsValue * 1.2 / doll).toFixed(2) / (100 - discount) * 100).toFixed(2); // Пересчитываем обратно в доллары
            } else if (k.currencyType === 3) {
                // Евро
                notNdsValue = (discountedPrice * euro) / 1.2;
                if (!isInvoice) {
                    notNdsValue = Math.ceil((discountedPrice * euro) / 1.2); // Конвертируем, вычитаем НДС и округляем вверх
                }
                newPrice = (parseFloat(notNdsValue * 1.2 / euro).toFixed(2) / (100 - discount) * 100).toFixed(2); // Пересчитываем обратно в евро
            } else if (k.currencyType === 2) {
                // Гривны
                notNdsValue = discountedPrice;
                if (!isInvoice) {
                    notNdsValue = Math.ceil(discountedPrice); // Округляем вверх
                }
                newPrice = (notNdsValue / (100 - discount) * 100).toFixed(2);
            }

            roundedNotNds = notNdsValue;

            // Пересчес НДС"т "грн
            let withNdsValue = roundedNotNds * 1.2; // Считаем с НДС
            let roundedWithNds = parseFloat(withNdsValue).toFixed(2);

            // Пересчет суммы с учетом количества
            let withCollAndNdsValue = roundedWithNds * coll;
            let roundedWithCollAndNds = parseFloat(withCollAndNdsValue).toFixed(2);

            return {
                ...k,
                originalPrice: isInvoice ? k.originalPrice : originalPrice, // Сохраняем оригинальную цену, если это не счёт
                price: newPrice, // Обновляем цену в валюте
                notNDS: roundedNotNds ?? 'wtf', // Грн без НДС
                withNds: roundedWithNds, // Грн с НДС
                withCollAndNds: roundedWithCollAndNds, // Общая сумма с количеством
            };
        });
    };


    const [showCreateButton, setShowCreateButton] = useState(true);

    function validateCP(CPObj) {
        let error = false;
        if (productionTime < 0) {
            error = true;
            toast.error("Введите корректные сроки изготовления")
        }
        if (companyId === "") {
            error = true;
            toast.error("Выберите компанию")
        }
        if ((!isInvoiceEdit && !isInvoice) && statusKp === "") {
            error = true;
            toast.error("Выберите статус КП")
        }
        if (newKp.length === 0) {
            error = true;
            toast.error("Добавьте позицию")
        }

        const allHaveName = CPObj.every(obj => obj.hasOwnProperty("name") && obj.name.trim() !== "");

        if (!allHaveName) {
            toast.error("Введите имена всех модулей!");
            error = true;
        }

        return error;
    }

    let showPrintButton = false;
    let showDeleteButton = false;

    const [generatedLink, setGeneratedLink] = useState('');

    const addNewKp = async () => {

        const recalculatedData = recalculation(newKp, isInvoice);
        setNewKp(recalculatedData);

        if (validateCP(recalculatedData)) return;

        try {
            let requestData = {
                lastIdKp: props.lastIdKp,
                newKp: recalculatedData,
                companyName,
                productionTime,
                companyId,
                contact,
                discount,
                euro,
                doll,
                mainSumF: mainSumF(),
                statusKp,
                number: props?.kpInfoByID?.[0]?.number,
                personalId: props.personalId,
                is_invoice: isInvoice,
                is_invoice_edit: isInvoiceEdit,
                is_invoice_selector: isInvoice,
                invoice_number: invoiceNumber ?? props.idKP,
            };
            console.log("requestData", requestData)
                //return ;
            await props.editKp(requestData).then((response) => {
                console.log("response", response)
                if (response?.status === 1) {
                    setShowKPLink(true);
                    setGeneratedLink('/check/' + response?.data?.id + '/' + (isInvoice || isInvoiceEdit || invoiceSaved ? 0 : 4));
                    console.log(generatedLink, generatedLink?.length > 0)
                } else {
                    setShowKPLink(false);
                    setGeneratedLink('')
                }
            }).catch((error) => {
                toast.error("Ошибка при выполнении запроса: " + error);

            })

        } catch (error) {
            toast.error("Ошибка при выполнении запроса: " + error);
        }
    };


    const updateKP = async () => {
        if (validateCP(newKp)) {
            return false;
        }
        try {
            const response = await props.updateKp({
                'discount': discount,
                'company_id': companyId,
                'companyName': companyName,
                'contact': contact,
                'productionTime': productionTime,
                'kpID': props.is_invoice
                    ? props?.kpInfoByID?.[0]?.id_invoice
                    : props.kpInfoByID?.[0]?.number,
                'newKp': newKp,
                'statusKp': statusKp,
                'mainSumF': mainSumF(),
                'personalId': props.personalId,
                'euro': euro ?? 0,
                'usd': doll ?? 0,
                'is_invoice_selector': isInvoice,
                'is_invoice': props.is_invoice,
                'is_invoice_edit': props.is_invoice_edit,
                'invoice_number': invoiceNumber,
            });

            if (response?.status === 1 && response?.data?.id !== undefined) {
                setShowKPLink(true);
                if (isInvoice || isInvoiceEdit) {
                    setShowCreateButton(false);
                    showPrintButton = false;
                    showDeleteButton = false;
                    invoiceSaved = true;
                }
            } else {
                setShowKPLink(false);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const deleteKP = async () => {
        try {
            const response = await props.deleteKp({
                'id': props.lastIdKp,
                'personalId': props.personalId
            });

            if (response?.status === 1) {
                setHideButtons(true);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const mainSumF = () => {
        let sum = recalculation(newKp).reduce((accumulator, currentValue) => {
            return Number(accumulator) + Number(currentValue.withCollAndNds);
        }, 0);
        return sum;
    }

    let isInvoiceEdit = props?.is_invoice_edit ?? null;

    const [isInvoice, setIsInvoice] = useState(isInvoiceEdit ? 1 : 0);
    let invoiceSaved = false;
    const [invoiceSum, setInvoiceSum] = useState(0);
    const [invoiceNumber, setInvoiceNumber] = useState(isInvoiceEdit ? props?.number : null);
    const [invoiceForm2, setInvoiceForm2] = useState(0);
    {/* invoiceForm2  invoiceSum invoice_number*/
    }

    const handleInvoiceForm2Change = (event) => {
        setInvoiceForm2(event.target.checked ? 1 : 0);
    }
    const handleInvoiceSumChange = (event) => {
        setInvoiceSum(event.target.value);
    }
    const handleInvoiceNumberChange = (event) => {
        setInvoiceNumber(event.target.value);
    }
    const handlePrint = () => {
        if (!isInvoice) {
            const recalculatedData = recalculation(newKp, isInvoice);
            setNewKp(recalculatedData); // Обновляем данные только перед печатью
        }
        setShowPrint(true);
    };

    return (
        <div>
            <div className={s.twoBlocksWithH1}>
                <div className={s.course}>
                    {props?.course?.map((k, index) => {
                        return <div className={index === 0 ? s.blueBg : s.yellowBg}
                                    key={"course" + index}>{k.currency + ": " + k.numeral}</div>
                    })}
                </div>
            </div>
            {addAgent &&
                <Modal active={addAgent} setActive={setAddAgent}>
                    <AddAgents active={addAgent} setActive={setAddAgent} companyId={companyId}
                               setNewCompanyAgent={props.setNewCompanyAgent}/>
                </Modal>
            }
            {deleteKPWindow &&
                <Modal active={deleteKPWindow} setActive={setDeleteKPWindow}>
                    <div>
                        <h3>
                            Вы точно хотите удалить черновик? Данную операцию невозможно отменить
                        </h3>
                        <button
                            onClick={deleteKP}
                            className={main.inputDelete + " " + main.redBg + " " + main.center}
                        >
                            Я уверен
                        </button>
                    </div>
                </Modal>
            }
            <FormGroup style={{marginTop: '10px'}}>
                <Stack direction="row" spacing={1} alignItems="center">
                    {!props?.is_invoice_edit &&
                        <>
                            <Typography>КП</Typography>
                            <Switch checked={isInvoice}
                                    onClick={() => setIsInvoice(!isInvoice)}
                                    disabled={props.is_invoice_edit}/>
                            <Typography>Счёт</Typography>
                        </>
                    }
                    {isInvoice && !props.is_invoice_edit ?
                        <TextField
                            type="number"
                            value={invoiceNumber}
                            label={"№ счёта"}
                            size={"small"}
                            step="0.01"
                            min="0.00"
                            name="invoice_number"
                            onChange={handleInvoiceNumberChange}
                            style={{width: '150px'}}
                            required={isInvoice === 1}
                        />
                        : ''
                    }

                </Stack>
            </FormGroup>


            <h1>
                {
                    (props?.kpInfoByID?.length > 0 || props.idKp || (!isInvoice && isInvoiceEdit))
                        ? 'Редактировать '
                        : 'Создать '
                }
                {isInvoiceEdit || isInvoice ? 'счёт ' : 'КП '}
                {props?.kpInfoByID?.length > 0
                    ? ('№' + (isInvoice && !isInvoiceEdit ? invoiceNumber : props.kpInfoByID?.[0]?.number) + " (" + companyName + ")")
                    : ''
                }
            </h1>
            <div className={s.data}>
                <div>
                    <div>
                        <div className={main.bold + " " + main.center}>Выбор компании</div>
                        <div>
                            <input type="text" list="company" value={companyName} placeholder="Не выбрано"
                                   onChange={setCompanyIdF} className={main.input}/>
                            <datalist id="company">
                                {props?.companyList?.map(k => (
                                    <option
                                        key={"company" + k.id}
                                        value={k.name_with_akon}
                                        data={k.id}
                                    >
                                        {k.name_with_akon}
                                    </option>
                                ))}
                            </datalist>
                        </div>
                    </div>
                    {companyId !== "" &&
                        <div>
                            <div className={main.bold + " " + main.center}>Выбор контакта</div>
                            <div className={s.selectPlus}>
                                <div>
                                    <select
                                        onChange={setContactF}
                                        className={s.select}
                                        value={contact}
                                    >
                                        <option value="">Не выбрано</option>
                                        {props.companyAgents.map(k =>
                                            <option
                                                key={"companyAgents" + k.id}
                                                value={k.email}
                                            >{k.name}</option>)}
                                    </select>
                                </div>
                                <div><FontAwesomeIcon icon={faPlus} onClick={() => setAddAgent(true)}
                                                      className={s.ahref}/></div>
                            </div>
                        </div>
                    }

                    <div style={{width: '100%', display: (isInvoiceEdit || isInvoice) ? 'none' : 'inline-block'}}>
                        <div className={main.bold + " " + main.center}>Выбор статуса КП</div>
                        <div>
                            <select
                                onChange={setStatusKpF}
                                className={s.select}
                                value={statusKp}
                            >
                                <option value="">Не выбрано</option>
                                {props.statusKp.map(k =>
                                    <option
                                        key={"statusKp" + k.id}
                                        value={k.id}
                                    >{k.name}</option>)}
                            </select>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={main.bold + " " + main.center}>Скидка</div>
                    <div><input type="number" value={discount} step="1" min="0" name="discount"
                                className={s.input} onChange={handleDiscountChange}/></div>
                    <div className={main.bold + " " + main.center}>Изготовление в днях</div>
                    <div><input type="number" value={productionTime} name="productionTime"
                                className={s.input} onChange={handleProductionTimeChange}
                                style={{borderColor: productionTime ? '#d2d2d2' : 'red'}}/></div>
                </div>
                <div>
                </div>
                <div>
                    <div className={main.bold + " " + main.center}>Курс долл</div>
                    <div><input type="number" value={doll} step="0.01" min="0.00" name="doll"
                                onChange={handleCurrencyChange} className={s.input}/></div>
                    <div className={main.bold + " " + main.center}>Курс евро</div>
                    <div><input type="number" value={euro} step="0.01" min="0.00" name="euro"
                                onChange={handleCurrencyChange} className={s.input}/></div>
                </div>

                <div>
                    <div className={main.bold + " " + main.center}>Полезные ссылки</div>
                    <div className={main.center + " " + main.p20}><NavLink to={"/personalModule/6"} target="_blank"
                                                                           className={s.ahref}>К
                        номенклатурам</NavLink>
                    </div>
                    <div className={main.center + " " + main.p20}><NavLink to={"/tech/future"} target="_blank"
                                                                           className={s.ahref}>Фьючер</NavLink>
                    </div>
                </div>
            </div>

            <div><input type="button" onClick={openDialog} className={main.inputAdd}
                        value="Добавить позицию"/>
            </div>

            <Dialog open={dialogOpen} onClose={closeDialog}>
                <DialogTitle style={{textAlign: 'center'}}>Выберите тип продукта</DialogTitle>
                <DialogContent>
                    <Button
                        fullWidth
                        variant="contained"
                        color="success"
                        onClick={() => handleProductTypeChange({target: {value: 'internal'}})}
                        style={{marginBottom: '10px'}}
                    >
                        Внутренняя продукция
                    </Button>
                    <Button
                        fullWidth
                        variant="contained"
                        color='secondary'
                        onClick={() => handleProductTypeChange({target: {value: 'external'}})}
                    >
                        Стороння продукция
                    </Button>
                </DialogContent>
            </Dialog>


            <div className={s.mainCheck + " " + main.header}>
                <div>#</div>
                <div>Название</div>
                <div>Номенклатура Акон</div>
                <div>Описание</div>
                <div>Кол</div>
                <div>Цена</div>
                <div>Учёт скидки</div>
                <div>Действия</div>
            </div>
            {newKp.map((k, index) => {
                return (
                    <div key={"newKp" + index} className={s.mainCheckDiv}>
                        <div>{index + 1}</div>
                        <div>
                            <TextField
                                variant="outlined"
                                value={k.name}
                                onChange={(e) => setNewValueF(index, e, "name")}
                                className={s.input}
                                InputProps={{
                                    readOnly: k.internal_product,
                                }}
                                disabled={k.internal_product}
                            />
                        </div>
                        <div className={main.center} style={{marginRight: '10px'}}>
                            <Autocomplete
                                options={options}
                                getOptionLabel={(option) => option.label}
                                loading={isLoading}
                                value={k.nomenclatureAkon}
                                onChange={async (event, newValue) => {
                                    setNewDataF(index, newValue, "nomenclatureAkon");
                                    if (newValue && newValue.label) {
                                        setNewValueF(index, {target: {value: newValue.label}}, "name");

                                        try {
                                            const data = await specificationAPI.getNomenclaturesData(newValue.id);
                                            setNewValueF(index, {
                                                target: {
                                                    value: `${data?.[0]?.['DESCRIPTION_UA']}\n${data?.[0]?.['desc']}${data?.[0]?.['ADD_OPTIONS']?.length > 0 ? `\n Опції:${data?.[0]?.ADD_OPTIONS}` : ''}`
                                                }
                                            }, "desc");
                                        } catch (error) {
                                            console.error("Error fetching nomenclatures data:", error);
                                        }
                                    }
                                }}

                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder="Номенклатура Акон"
                                        className={s.input}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {isLoading ? <CircularProgress color="inherit" size={20}/> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}

                                    />
                                )}
                            />
                        </div>
                        <div>
                            <TextField
                                variant="outlined"
                                multiline
                                value={k.desc}
                                onChange={(e) => setNewValueF(index, e, "desc")}
                                className={s.input}
                                InputProps={{
                                    readOnly: k.internal_product,
                                }}

                                disabled={k.internal_product}
                            />
                        </div>
                        <div>
                            <TextField
                                type="number"
                                variant="outlined"
                                value={k.coll}
                                onChange={(e) => setNewValueF(index, e, "coll")}
                                className={s.input + " " + main.center}
                            />
                        </div>
                        <div className={main.center + " " + s.priceCurrency}>
                            <div>
                                <TextField
                                    type="number"
                                    variant="outlined"
                                    value={k.price}
                                    onChange={(e) => setNewValueF(index, e, "price")}
                                    className={s.input + " " + s.inputNumber + " " + main.center}
                                    step={k.currencyType === 2 ? "0.01" : "0"}
                                    min={k.currencyType === 2 ? "0.00" : "0"}
                                />
                            </div>
                            <div className={s.currency}>
                                <div className={k.currencyType === 1 ? s.green : ""}
                                     onClick={() => setNewDataF(index, 1, "currencyType")}>$
                                </div>
                                <div className={k.currencyType === 2 ? s.green : ""}
                                     onClick={() => setNewDataF(index, 2, "currencyType")}>грн
                                </div>
                                <div className={k.currencyType === 3 ? s.green : ""}
                                     onClick={() => setNewDataF(index, 3, "currencyType")}>евро
                                </div>
                            </div>
                        </div>
                        <div className={main.center + " " + s.currency}>
                            <div className={k.discountType === 1 ? s.green : ""}
                                 onClick={() => setNewDataF(index, 1, "discountType")}>Да
                            </div>
                            <div className={k.discountType === 2 ? s.green : ""}
                                 onClick={() => setNewDataF(index, 2, "discountType")}>Нет
                            </div>
                        </div>
                        <div className={main.center}>
                            <span onClick={() => deleteObjectF(index)}>
                                <FontAwesomeIcon icon={faTrash} className={s.iconDelete}/>
                            </span>
                        </div>
                    </div>
                );
            })}
            <br/>

            {hideButtons === false && !kpSaved && props?.kpInfoByID?.length === undefined &&
                <div className={s.mainCheck}>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div style={{display: 'inline-block'}}>
                        <button
                            className={main.inputDelete + " " + main.orangeBg}
                            style={{width: '100%', display: isInvoice || isInvoiceEdit ? 'none' : 'inline-block'}}
                            onClick={() => setDeleteKPWindow(true)}
                        >
                            Удалить черновик
                        </button>
                    </div>
                </div>
            }

            {showPrint &&
                <Modal2 active={showPrint} setActive={setShowPrint}>
                    <PrintKp
                        newKp={recalculation(newKp)}
                        company={companyName}
                        collSum={collSum}
                        sum={mainSumF()}
                        contact={contact}
                        productionTime={productionTime}
                        lastIdKp={parseInt(props?.lastIdKp) === 0 ? props?.kpInfoByID?.[0]?.number : props?.lastIdKp}
                        discount={discount}
                        workerData={props.workerData}
                    />
                </Modal2>
            }

            <div className={s.mainCheck}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div>
                    {!(isInvoice || isInvoiceEdit) &&
                        <button className={main.inputAdd + ' ' + main.greenBg}
                                style={{
                                    display: (isInvoice === 1 || isInvoiceEdit || showPrintButton) ? 'none' : 'inline-block',
                                    width: '100%'
                                }}
                                onClick={
                                    handlePrint
                                    //() => {
                                    // const recalculatedData = recalculation(newKp, isInvoice);
                                    // setNewKp(recalculatedData); // Обновляем данные только если это не "счёт"
                                    // setShowPrint(true);
                                    //    }
                                }
                        >Сформировать для печати
                        </button>

                    }
                </div>
            </div>

            {generatedLink?.length > 0 && (
                <div className={s.mainCheck}>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div style={{display: 'inline-block'}}>
                        <Link to={generatedLink} target='_blank'>
                            <button
                                className={main.inputAdd + " " + main.orangeBg}
                                style={{width: '100%'}}
                            >
                                Просмотреть {(props?.is_invoice_edit || isInvoice) ? 'счёт' : 'КП'}
                            </button>
                        </Link>
                    </div>
                </div>
            )}

            {hideButtons === false && !kpSaved && newKp.length > 0 && (
                <div className={s.mainCheck}>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div style={{display: 'inline-block'}}>
                        <button
                            className={main.inputAdd + " " + main.greenBg}
                            style={{width: '100%', display: showCreateButton ? 'inline-block' : 'none'}}
                            onClick={() => {
                                addNewKp()
                            }}
                        >
                            Сохранить {(props?.is_invoice_edit || isInvoice) ? 'счёт' : 'КП'}
                        </button>
                    </div>
                </div>
            )}


        </div>
    );
}

export default AddNewKp;
